import {
  EventMarketingKeys,
  EventSpecAdKeys,
  EventSpecCartKeys,
  EventSpecCategoryKeys,
  EventSpecCommonKeys,
  EventSpecOfferKeys,
  EventSpecPcmKeys,
  EventSpecProductKeys,
  EventSpecSearchKeys,
  EventSpecSupplierKeys,
} from './EventSpecKeys';

export const eventSpecDictionary = {
  shop_product: [
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.brand,
    EventSpecCategoryKeys.category_string,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level,
    EventSpecProductKeys.price,
    EventSpecProductKeys.credit_monthly_price,
    EventSpecProductKeys.offers_quantity,
    EventSpecProductKeys.kd_offers_count,
    EventSpecProductKeys.is_discount,
    EventSpecProductKeys.test_discount_amount,
    EventSpecCommonKeys.path,
    EventSpecCommonKeys.ref,
    EventSpecCommonKeys.source,
    EventSpecCommonKeys.started_from,
    EventSpecCommonKeys.started_by,
    EventSpecCommonKeys.available_filters,
    EventSpecCommonKeys.category_id,
    EventSpecCommonKeys.listing_position,
    EventSpecCategoryKeys.category_level_4,
    EventSpecProductKeys.created_time,
    EventSpecProductKeys.has_big_picture,
    EventSpecProductKeys.has_configurator,
    EventSpecProductKeys.has_description,
    EventSpecProductKeys.has_video_review,
    EventSpecProductKeys.image_count,
    EventSpecCommonKeys.inner_source,
    EventSpecAdKeys.is_promoted,
    EventSpecAdKeys.ads_supplier_id,
    EventSpecProductKeys.mabaya_adds_count,
    EventSpecProductKeys.number_of_orders,
    EventSpecProductKeys.product_category,
    EventSpecCommonKeys.product_type,
    EventSpecCommonKeys.promo,
    EventSpecProductKeys.rating,
    EventSpecCommonKeys.referrer,
    EventSpecProductKeys.review_count,
    EventSpecCommonKeys.ssohash,
    EventSpecSupplierKeys.supplier,
    EventSpecPcmKeys.cross_sell_backdrop_product_id,
    EventSpecPcmKeys.cross_sell_backdrop_product_name,
    EventSpecPcmKeys.cross_sell_backdrop_listing_position,
    EventSpecPcmKeys.cross_sell_backdrop_count,
    EventSpecPcmKeys.block_id,
    EventSpecPcmKeys.block_name,
    EventSpecSearchKeys.query_id,
    EventSpecSearchKeys.search_term,
    EventSpecProductKeys.seller_rating,
    EventMarketingKeys.utm_campaign,
    EventMarketingKeys.utm_source,
    EventMarketingKeys.utm_medium,
    EventMarketingKeys.utm_content,
    EventMarketingKeys.utm_term,
    EventMarketingKeys.campaign_id,
    EventMarketingKeys.fbclid,
    EventMarketingKeys.ttclid,
    EventMarketingKeys.gclid,
  ],
  shop_product_merchant_select: [
    'cta_value',
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.brand,
    EventSpecCategoryKeys.category_string,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level,
    EventSpecProductKeys.price,
    EventSpecProductKeys.credit_monthly_price,
    EventSpecProductKeys.offers_quantity,
    EventSpecProductKeys.kd_offers_count,
    EventSpecCommonKeys.path,
    EventSpecCommonKeys.ref,
    EventSpecCommonKeys.source,
    EventSpecCommonKeys.started_from,
    EventSpecCommonKeys.started_by,
  ],
  shop_cart: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecCartKeys.total_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecCartKeys.cart_quantity_not_available,
    EventSpecCartKeys.cart_quantity_price_change,
    EventSpecCartKeys.cart_quantity_discount,
    EventSpecCartKeys.magnum_subtotal_price,
    'cart_quantity_other_supplier',
    EventSpecCommonKeys.started_from,
    'type',
  ],
  shop_add_to_cart_click: [
    EventSpecCommonKeys.page_category,
    EventSpecProductKeys.quantity,
    EventSpecSupplierKeys.supplier_id,
    EventSpecSupplierKeys.supplier,
    EventSpecSupplierKeys.supplier_position,
    EventSpecSupplierKeys.supplier_rating,
    EventSpecSupplierKeys.supplier_review_count,
    EventSpecSupplierKeys.supplier_price,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.brand,
    EventSpecCategoryKeys.category_string,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level,
    EventSpecProductKeys.price,
    EventSpecProductKeys.credit_monthly_price,
    EventSpecProductKeys.offers_quantity,
    EventSpecProductKeys.kd_offers_count,
    EventSpecProductKeys.is_discount,
    EventSpecProductKeys.test_discount_amount,
    EventSpecCommonKeys.path,
    EventSpecCommonKeys.ref,
    EventSpecCommonKeys.source,
    EventSpecCommonKeys.started_from,
    EventSpecCommonKeys.started_by,
    EventSpecProductKeys.created_time,
    EventSpecOfferKeys.delivery_cost,
    EventSpecOfferKeys.delivery_date,
    EventSpecOfferKeys.delivery_options,
    EventSpecProductKeys.has_configurator,
    EventSpecProductKeys.has_description,
    EventSpecProductKeys.has_video_review,
    EventSpecProductKeys.image_count,
    EventSpecProductKeys.product_category,
    EventSpecCommonKeys.promo,
    EventSpecCommonKeys.product_type,
    EventSpecProductKeys.rating,
    EventSpecCommonKeys.referrer,
    EventSpecCommonKeys.ssohash,
    EventSpecProductKeys.stock,
    EventSpecProductKeys.review_count,
    EventSpecCommonKeys.category_id,
    EventSpecAdKeys.is_promoted,
    EventSpecAdKeys.ads_supplier_id,
    EventSpecAdKeys.ads_unique_id,
    EventSpecPcmKeys.cross_sell_backdrop_product_id,
    EventSpecPcmKeys.cross_sell_backdrop_product_name,
    EventSpecPcmKeys.cross_sell_backdrop_listing_position,
    EventSpecPcmKeys.cross_sell_backdrop_count,
    EventSpecPcmKeys.block_id,
    EventSpecPcmKeys.block_name,
    EventSpecCommonKeys.listing_position,
    EventSpecProductKeys.seller_rating,
    'from_backdrop',
    'source_sku',

    // Offers filters
    EventSpecOfferKeys.result_count,
    EventSpecOfferKeys.sort_by,
    EventSpecOfferKeys.applied_filter,
    EventSpecOfferKeys.delivery_filter_value,
    EventSpecOfferKeys.search_term,
    EventSpecSearchKeys.query_id,
  ],
  shop_cart_select_another_supplier_click: [
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.brand,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_string,
    EventSpecCategoryKeys.category_level,
    EventSpecProductKeys.price,
    EventSpecProductKeys.credit_monthly_price,
    EventSpecProductKeys.offers_quantity,
    EventSpecCommonKeys.path,
    EventSpecCommonKeys.ref,
    EventSpecCommonKeys.source,
    EventSpecCommonKeys.started_from,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecCartKeys.total_price,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    'supplier_name_not_in_stock',
    'supplier_id_not_in_stock',
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
  ],
  shop_view_similar_goods_click: [
    EventSpecProductKeys.brand,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_quantity_discount,
    EventSpecCartKeys.cart_quantity_not_available,
    EventSpecCartKeys.cart_quantity_price_change,
    EventSpecCartKeys.cart_size,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCartKeys.magnum_subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
    EventSpecCartKeys.total_price,
  ],
  shop_cart_not_available_view: [
    EventSpecProductKeys.brand,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_quantity_discount,
    EventSpecCartKeys.cart_quantity_not_available,
    EventSpecCartKeys.cart_quantity_price_change,
    EventSpecCartKeys.cart_size,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecCartKeys.magnum_subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
    EventSpecCartKeys.total_price,
    'button',
  ],
  shop_similar_goods_backdrop_view: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_quantity_discount,
    EventSpecCartKeys.cart_quantity_not_available,
    EventSpecCartKeys.cart_quantity_price_change,
    EventSpecCartKeys.cart_size,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCartKeys.magnum_subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
    EventSpecCartKeys.total_price,
  ],
  shop_cart_product_quantity_increase: [
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.brand,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_string,
    EventSpecCommonKeys.inner_source,
    EventSpecCommonKeys.path,
    EventSpecProductKeys.price,
    EventSpecCommonKeys.product,
    EventSpecProductKeys.product_category,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.quantity,
    EventSpecCommonKeys.ref,
    EventSpecCommonKeys.source,
    EventSpecCommonKeys.started_from,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
  ],
  shop_cart_product_quantity_decrease: [
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.brand,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_string,
    EventSpecCommonKeys.inner_source,
    EventSpecCommonKeys.path,
    EventSpecProductKeys.price,
    EventSpecCommonKeys.product,
    EventSpecProductKeys.product_category,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.quantity,
    EventSpecCommonKeys.ref,
    EventSpecCommonKeys.source,
    EventSpecCommonKeys.started_from,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
  ],
  shop_cart_product_remove: [
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCommonKeys.path,
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.product_category,
    EventSpecProductKeys.brand,
    EventSpecProductKeys.price,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecProductKeys.quantity,
    EventSpecCategoryKeys.category_string,
    EventSpecCommonKeys.inner_source,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecCommonKeys.started_by,
    EventSpecCartKeys.selection_apply,
    EventSpecCartKeys.delete_quantity,
  ],
  shop_cart_product_not_available_delete: [
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.brand,
    EventSpecCategoryKeys.category_string,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level,
    EventSpecProductKeys.price,
    EventSpecCommonKeys.path,
    EventSpecCommonKeys.ref,
    EventSpecCommonKeys.source,
    EventSpecCommonKeys.started_from,
    EventSpecCartKeys.subtotal_price,
    EventSpecCartKeys.total_price,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.supplier_name_not_in_stock,
    EventSpecCartKeys.supplier_id_not_in_stock,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
  ],
  shop_cart_remove_alert: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecCartKeys.delete_quantity,
    EventSpecCommonKeys.inner_source,
    EventSpecCommonKeys.path,
    EventSpecCommonKeys.product,
    EventSpecProductKeys.product_id,
    EventSpecCartKeys.selection_apply,
    EventSpecCommonKeys.source,
    EventSpecCommonKeys.started_by,
    EventSpecCommonKeys.started_from,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
  ],
  shop_cart_alert_view: [
    EventSpecCartKeys.cart_id,
    'text',
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
  ],
  shop_cart_supplier_click: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecSupplierKeys.supplier,
    EventSpecSupplierKeys.supplier_id,
    'cart_quantity_supplier',
  ],
  shop_cart_delete_selected_click: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_changed,
    EventSpecCategoryKeys.category_level_2_changed,
    EventSpecCategoryKeys.category_level_3_changed,
    'suppliers_name_remove',
    'suppliers_id_remove',
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    'quantity_remove',
  ],
  shop_cart_remove_option_select: [
    EventSpecCommonKeys.inner_source,
    'option',
    EventSpecCommonKeys.product,
    EventSpecCommonKeys.source,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecCartKeys.delete_quantity,
    EventSpecCommonKeys.path,
    EventSpecProductKeys.product_id,
    EventSpecCartKeys.selection_apply,
    EventSpecCommonKeys.started_by,
    EventSpecCommonKeys.started_from,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
  ],
  shop_cart_checkbox_click: [
    'type_click',
    'selected_item',
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecCartKeys.subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
  ],
  shop_cart_not_available_detail_click: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_changed,
    EventSpecCategoryKeys.category_level_2_changed,
    EventSpecCategoryKeys.category_level_3_changed,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    'amount_unique',
    EventSpecCartKeys.supplier_name_not_in_stock,
    EventSpecCartKeys.supplier_id_not_in_stock,
  ],
  shop_cart_price_change_detail_click: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_changed,
    EventSpecCategoryKeys.category_level_2_changed,
    EventSpecCategoryKeys.category_level_3_changed,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    'amount_unique',
    'price_change',
    EventSpecSupplierKeys.supplier_name,
    EventSpecSupplierKeys.supplier_id,
  ],
  shop_cart_continue_button_click: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecCommonKeys.path,
    'selected_items',
    'selected_merchants',
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecCartKeys.cart_quantity_not_available,
    EventSpecCartKeys.cart_quantity_price_change,
  ],
  shop_favorite_add: [
    EventSpecCommonKeys.page_category,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.product_name,
    EventSpecProductKeys.brand,
    EventSpecCategoryKeys.category_string,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level,
    EventSpecProductKeys.price,
    EventSpecProductKeys.credit_monthly_price,
    EventSpecProductKeys.offers_quantity,
    EventSpecProductKeys.kd_offers_count,
    EventSpecProductKeys.is_discount,
    EventSpecCommonKeys.path,
    EventSpecCommonKeys.ref,
    EventSpecCommonKeys.source,
    EventSpecCommonKeys.started_from,
    EventSpecCommonKeys.started_by,
    EventSpecCommonKeys.listing_position,
    EventSpecSearchKeys.search_term,
    EventSpecAdKeys.is_promoted,
    EventSpecAdKeys.ads_supplier_id,
    EventSpecPcmKeys.cross_sell_backdrop_product_id,
    EventSpecPcmKeys.cross_sell_backdrop_product_name,
    EventSpecPcmKeys.cross_sell_backdrop_listing_position,
    EventSpecPcmKeys.cross_sell_backdrop_count,
    EventSpecPcmKeys.block_id,
    EventSpecPcmKeys.block_name,
    EventSpecSearchKeys.query_id,
    EventSpecCommonKeys.ssohash,
  ],
  shop_checkout_alert_error_view: [
    'alert_type',
    EventSpecCommonKeys.page_category,
    EventSpecCartKeys.saved_address_count,
    EventSpecCartKeys.delivery_suborders_count,
    EventSpecCartKeys.postomat_suborders_count,
    EventSpecCartKeys.pickup_suborders_count,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.delivery_price,
    EventSpecCartKeys.subtotal_price,
    EventSpecCartKeys.total_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.suppliers,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecCartKeys.available_delivery_method,
  ],
  shop_checkout_delivery_method_select: [
    EventSpecCartKeys.delivery_method,
    EventSpecCartKeys.available_delivery_method,
    EventSpecCartKeys.cart_id,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCartKeys.delivery_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.suppliers,
    EventSpecCartKeys.total_price,
  ],
  shop_checkout_nearby_postomat_view: [
    EventSpecCartKeys.available_delivery_method,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecCartKeys.delivery_price,
    EventSpecCartKeys.delivery_suborders_count,
    EventSpecCommonKeys.path,
    EventSpecCartKeys.pickup_suborders_count,
    EventSpecCartKeys.postomat_suborders_count,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.saved_address_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
    EventSpecCartKeys.total_price,
  ],
  shop_checkout_nearby_postomat_accept: [
    EventSpecCartKeys.available_delivery_method,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecCartKeys.delivery_price,
    EventSpecCartKeys.delivery_suborders_count,
    EventSpecCommonKeys.path,
    EventSpecCartKeys.pickup_suborders_count,
    EventSpecCartKeys.postomat_suborders_count,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.saved_address_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
    EventSpecCartKeys.total_price,
  ],
  shop_checkout_nearby_postomat_reject: [
    EventSpecCartKeys.available_delivery_method,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecCartKeys.delivery_price,
    EventSpecCartKeys.delivery_suborders_count,
    EventSpecCommonKeys.path,
    EventSpecCartKeys.pickup_suborders_count,
    EventSpecCartKeys.postomat_suborders_count,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.saved_address_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
    EventSpecCartKeys.total_price,
  ],
  test_product_with_discount_view: [
    EventSpecCommonKeys.applied_filter,
    EventSpecProductKeys.product_id,
    EventSpecCategoryKeys.category_level,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_string,
    EventSpecCommonKeys.started_by,
    EventSpecSupplierKeys.supplier_id,
    EventSpecSupplierKeys.supplier,
    EventSpecSupplierKeys.supplier_position,
    EventSpecCommonKeys.page_category,
    EventSpecCommonKeys.value,
  ],
  shop_checkout_nearby_postomat_map: [
    EventSpecCartKeys.available_delivery_method,
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_3_count,
    'clicked_form',
    EventSpecCartKeys.delivery_price,
    'nearby_button_clicked',
    EventSpecCommonKeys.path,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.postomat_suborders_count,
    EventSpecCartKeys.saved_address_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecSupplierKeys.supplier_count,
    EventSpecSupplierKeys.suppliers,
    EventSpecCartKeys.total_price,
  ],
  test_merchant_with_discount_view: [
    EventSpecProductKeys.product_id,
    EventSpecCategoryKeys.category_level,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_string,
    EventSpecCommonKeys.started_by,
    EventSpecSupplierKeys.supplier_id,
    EventSpecSupplierKeys.supplier,
    EventSpecSupplierKeys.supplier_position,
    EventSpecCommonKeys.page_category,
  ],

  /********* SN-1974 enhanced offers filters test *********/

  test_shop_product_sort_click: [
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecProductKeys.offers_quantity,
    EventSpecProductKeys.product_id,
    EventSpecOfferKeys.sort_by,
    EventSpecOfferKeys.applied_filter,
    EventSpecOfferKeys.delivery_filter_value,
    EventSpecOfferKeys.product_page,
  ],

  test_shop_product_sort_apply: [
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.offers_quantity,
    EventSpecOfferKeys.sort_by,
    EventSpecOfferKeys.sort_applied,
    EventSpecOfferKeys.applied_filter,
    EventSpecOfferKeys.delivery_filter_value,
    EventSpecOfferKeys.product_page,
  ],

  test_shop_product_delivery_backdrop: [
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecProductKeys.product_id,
    EventSpecOfferKeys.sort_by,
    EventSpecOfferKeys.product_page,
  ],

  test_shop_product_filter_apply: [
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.offers_quantity,
    EventSpecOfferKeys.sort_by,
    EventSpecOfferKeys.applied_filter,
    EventSpecOfferKeys.delivery_filter_value,
    EventSpecOfferKeys.result_count,
    EventSpecOfferKeys.product_page,
  ],

  test_shop_product_filter_reset: [
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.offers_quantity,
    EventSpecOfferKeys.sort_by,
    EventSpecOfferKeys.applied_filter,
    EventSpecOfferKeys.delivery_filter_value,
    EventSpecOfferKeys.result_count,
    EventSpecOfferKeys.product_page,
  ],

  test_shop_product_search_query_start: [
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.offers_quantity,
    EventSpecOfferKeys.sort_by,
    EventSpecOfferKeys.applied_filter,
    EventSpecOfferKeys.delivery_filter_value,
    EventSpecOfferKeys.search_term,
  ],

  test_shop_product_search_results_view: [
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecProductKeys.product_id,
    EventSpecProductKeys.offers_quantity,
    EventSpecOfferKeys.result_count,
    EventSpecOfferKeys.sort_by,
    EventSpecOfferKeys.applied_filter,
    EventSpecOfferKeys.delivery_filter_value,
    EventSpecOfferKeys.search_term,
  ],

  shop_progress_bar_click: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCartKeys.subtotal_price,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.supplier_id,
    EventSpecSupplierKeys.supplier_count,
    EventSpecCartKeys.suborder_count,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
  ],

  shop_progress_bar_backdrop_view: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.supplier_id,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecProductKeys.product_id,
    'listitems_total_count',
  ],

  shop_progress_bar_backdrop_click: [
    EventSpecCartKeys.cart_id,
    EventSpecCartKeys.cart_quantity,
    EventSpecCartKeys.cart_size,
    EventSpecProductKeys.promo_sku_count,
    EventSpecCommonKeys.path,
    EventSpecSupplierKeys.supplier_id,
    EventSpecCategoryKeys.category_level_1,
    EventSpecCategoryKeys.category_level_2,
    EventSpecCategoryKeys.category_level_3,
    EventSpecCategoryKeys.category_level_1_count,
    EventSpecCategoryKeys.category_level_2_count,
    EventSpecCategoryKeys.category_level_3_count,
    EventSpecProductKeys.product_id,
    'listitems_total_count',
  ],

  /********* *********/
};
