/**
 * ? Events for Firebase, Amplitude, AWS
 */

import { Cookies } from 'ks-utilities/lib/cookies';

import { CONSTANTS } from '../../const/Constants';
import { QuerySourceType } from '../../const/QuerySourceType';
import { TypeTabs } from '../../const/TabBar';
import { UnitType } from '../../const/UnitProduct';
import { findCityName, getCurrentCityId } from '../../helpers/locationHelpers';
import { ApproveSignType } from '../../pages/checkout/const/ApproveSignType';
import { CheckoutMethod } from '../../pages/checkout/const/CheckoutMethod';
import { PaymentStepTypes } from '../../pages/checkout/const/PaymentStepTypes';
import { crossSellAnalyticName } from '../../services/pcm/pcmCrossSellService';
import { PCMBlockTypes } from '../../services/pcm/pcmService';
import { IAnalyticEventRawData, IPersonalizeRawData } from '../AnalyticService';
import { PageCategory } from '../const/PageCategory';
import { productUnitType } from '../const/productUnitType';
import { StartedByTypes } from '../const/StartedByTypes';
import { combineListingEventProperties } from '../helpers/combineListingEventProperties';
import { combineMarketingEventProperties } from '../helpers/combineMarketingEventProperties';
import { combineOffersFiltersEventProperties } from '../helpers/combineOffersFiltersEventProperties';
import { combineProductEventProperties } from '../helpers/combineProductEventProperties';
import { combineProductReviewsEventProperties } from '../helpers/combineProductReviewsEventProperties';
import { combineSearchEventProperties } from '../helpers/combineSearchEventProperties';
import { contExpressDeliveryOption } from '../helpers/delivery/contExpressDeliveryOption';
import { getAvailableDeliveryMethod } from '../helpers/delivery/getAvailableDeliveryMethod';
import { getBaseDeliveryOptions } from '../helpers/delivery/getBaseDeliveryOptions';
import { getCartInDeliveryParams } from '../helpers/delivery/getCartInDeliveryParams';
import { getCheckoutPostomatParams } from '../helpers/delivery/getCheckoutPostomatParams';
import { getDeliveryOptionsParams } from '../helpers/delivery/getDeliveryOptionsParams';
import { getAdInfo, getAdInfoFromData } from '../helpers/getAdInfo';
import { getAuctionBannerData } from '../helpers/getAuctionBannerData';
import { getBaseParams } from '../helpers/getBaseParams';
import {
  getCartParams,
  getProductInCartParams,
  getProductWithOneCategoryInCartParams,
} from '../helpers/getCartParams';
import { getCategoriesParams } from '../helpers/getCategoriesParams';
import { getCategoryHierarchyArr } from '../helpers/getCategoryHierarchyArr';
import {
  getCategoryHierarchyProps,
  getMultipleEntriesCategoryHierarchyProps,
} from '../helpers/getCategoryHierarchyProps';
import { getChangedCategoryLevels } from '../helpers/getChangedCategoryLevels';
import { getCheckoutDeliveryMethod } from '../helpers/getCheckoutDeliveryMethod';
import { getMagnumPcmBannerData } from '../helpers/getMagnumPcmBannerData';
import { getMainPromoBannerParams } from '../helpers/getMainPromoBannerParams';
import { getMerchantsInfo } from '../helpers/getMerchanstInfo';
import {
  getMyOrderDetailsParam,
  getMyOrdersParam,
} from '../helpers/getMyOrdersParams';
import { getOfferBaseParams } from '../helpers/getOfferBaseParams';
import { getPageCategory } from '../helpers/getPageCategory';
import { getFromUrl } from '../helpers/getParamFromSearchQuery';
import { getPcmBlockData } from '../helpers/getPcmBlockData';
import { getPcmCrossSellData } from '../helpers/getPcmCrossSellData';
import { getProductInfo } from '../helpers/getProductInfo';
import { getProductPromo } from '../helpers/getProductPromo';
import { getProductReviewParams } from '../helpers/getProductReviewParams';
import {
  getRefundDetailsParams,
  getRefundInListParams,
  getRefundInOrder,
} from '../helpers/getRefundDetailsParams';
import {
  getProductForRefundParams,
  getRefundInOrderParams,
} from '../helpers/getRefundParams';
import { getSearchTermFromPrevSearchPage } from '../helpers/getSearchTermFromPrevSearchPage';
import { getSessionId } from '../helpers/getSessionId';
import { getShareCartParams } from '../helpers/getShareCartParams';
import { getStartedFrom } from '../helpers/getStartedFrom';
import { getSuppliersIds } from '../helpers/getSuppliersIds';
import { getTransactionCartParams } from '../helpers/getTransactionCartParams';
import { TransactionFinalScreenType } from '../helpers/getTransactionFinalScreen';
import { getTransactionFromSessionCart } from '../helpers/getTransactionFromSessionCart';
import { getTransactionProductInfo } from '../helpers/getTransactionProductInfo';
import { isProductSelectedInCart } from '../helpers/isProductSelectedInCart';
import {
  clearStartedFromForSuitableItem,
  getStartedFromForSuitableItem,
} from '../helpers/startedFromForSuitableItem';
import { transformCreatedTime } from '../helpers/transformCreatedTime';

export class MobappMapper {
  m: any; // mithril

  constructor(m: any) {
    this.m = m;
    this.setUserProperties();
  }

  static innerSource(): any {
    const pageCategory = getPageCategory(document.referrer);
    const innerSource = pageCategory
      ? {
          inner_source: [pageCategory],
        }
      : {};

    return innerSource;
  }

  static cartQuantity(entries: IOrderEntry[]): number {
    let quantity = 0;

    entries.forEach((entry: { quantity: number; unit: IUnitProduct }) => {
      quantity += entry.unit.type === UnitType.MEASURABLE ? 1 : entry.quantity;
    });

    return quantity;
  }

  static combineCancellationData(data: IParamOrderPage) {
    const startedFrom = getStartedFrom();
    const baseParams = getBaseParams();
    const product = data.entries.map((entry) => entry.name);
    const parameters = {
      ...baseParams,
      ...startedFrom,
      product,
      current_status: data.currentStatus,
      path: [document.location.pathname + document.location.hash],
      source: getFromUrl.source(),
      ref: getFromUrl.ref(),
    };

    return parameters;
  }

  setUserProperties() {
    const currentCity = getCurrentCityId();
    const cities = window.BACKEND.components?.citySelection?.citys || [];
    const userProperties: IPersonalizeRawData = {
      props: {
        shop_city: findCityName(currentCity, cities),
        shop_test_group: Cookies.get('ks.tg'),
      },
    };
    window.ksService.AnalyticService.personalize(userProperties);
  }

  sendEvent(eventData: IAnalyticEventRawData) {
    window.ksService.AnalyticService.log(eventData);
  }

  clickedOnTabBar(buttonId: TypeTabs) {
    const eventName = 'shop_tab_bar_click';

    const path = document.location.pathname;
    const categoryArr =
      window.digitalData.product?.category ||
      window.digitalData.listing?.category;
    const categoryHierarchyProps = getCategoryHierarchyProps(categoryArr);

    const parameters = {
      button: buttonId,
      path,
      page_category: getPageCategory(path),
      ...categoryHierarchyProps,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCategoryNavigationPage() {
    const eventName = 'shop_category_navigation';

    const path = document.location.pathname;
    const categoryHierarchyProps = getCategoryHierarchyProps(
      window.digitalData.listing?.category
    );

    const parameters = {
      path,
      page_category: getPageCategory(path),
      ...categoryHierarchyProps,
      ref: getFromUrl.ref(),
      utm_campaign: getFromUrl.utmCampaign(),
      ...combineMarketingEventProperties(),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCategoryListing(data: IParamViewedPage) {
    const eventName = 'shop_category_listing_view';

    const appliedFilters = data.filters
      .filter((filter) => filter.active)
      .map((filter) => filter.title);

    const startedFrom =
      getStartedFromForSuitableItem() ?? getPageCategory(document.referrer);

    const eventPushData = {
      event: eventName,
      parameters: {
        ...combineListingEventProperties(data),
        applied_filter: appliedFilters?.length ? appliedFilters : null,
        ssohash: data.ssohash,
        test_model_tag_viewed: data.modelTagViewed, // TODO: remove after A/B test
        test_model_tag_clicked_count: data.modelTagClickedCount, // TODO: remove after A/B test
        ...combineMarketingEventProperties(),
        ...getStartedFrom(),
        value: appliedFilters?.length ? getFromUrl.categoryCode() : null,
        started_from: startedFrom,
      },
    };

    clearStartedFromForSuitableItem();

    this.sendEvent(eventPushData);
  }

  viewedProductWithDiscount(data: { filters: IFilters; card: IItemCard }) {
    const eventName = 'test_product_with_discount_view';
    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.card.category
    );
    const pageCategory = getPageCategory(window.location.pathname);

    const appliedFilters = data?.filters
      ?.filter((filter) => filter.active)
      .map((filter) => filter.title);

    const parameters = {
      ...categoryHierarchyProps,
      product_id: data.card.id,
      category_id: data.card.categoryId,
      applied_filter: appliedFilters?.length ? appliedFilters : null,
      page_category: pageCategory,
      value: data.card.discount,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedSearchResults(data: IParamViewedPage) {
    const eventName = 'shop_search_results_view';

    const appliedFilters = data.filters
      .filter((filter) => filter.active)
      .map((filter) => filter.title);
    const suggestionClicked =
      data.searchQuerySource === QuerySourceType.SUGGESTION;
    const historyClicked = data.searchQuerySource === QuerySourceType.HISTORY;
    const completionChipClicked = CONSTANTS.searchCompletionsTestName
      ? data.searchQuerySource === QuerySourceType.CHIPS
      : null;
    const startedFrom =
      getStartedFromForSuitableItem() ?? getPageCategory(document.referrer);

    const eventPushData = {
      event: eventName,
      parameters: {
        ...combineListingEventProperties(data),
        started_from: startedFrom,
        applied_filter: appliedFilters?.length ? appliedFilters : null,
        hint_clicked: suggestionClicked,
        hint_chips_click: getFromUrl.hintChipsClicked(),
        test_hint_chips_click: completionChipClicked,
        history_clicked: historyClicked,
        ssohash: data.ssohash,
        executed_query: data.externalSearchQueryInfo?.executedQuery,
        has_replacement: data.externalSearchQueryInfo?.hasReplacement,
        ...combineMarketingEventProperties(),
      },
    };

    clearStartedFromForSuitableItem();

    this.sendEvent(eventPushData);
  }

  clickedResetFilerByCategory(data: IBCatalogGridStoreType) {
    const eventName = 'shop_search_all_click';

    const eventPushData = {
      event: eventName,
      parameters: combineListingEventProperties(data),
    };

    this.sendEvent(eventPushData);
  }

  viewedFilterDialog(data: IBCatalogGridStoreType) {
    const eventName = 'shop_listing_filters_view';

    const eventPushData = {
      event: eventName,
      parameters: combineListingEventProperties(data),
    };

    this.sendEvent(eventPushData);
  }

  viewedFilterBackdrop(data: IParamViewedFilterBackdrop) {
    const eventName = 'shop_tag_filter_backdrop';

    const eventPushData = {
      event: eventName,
      parameters: {
        type: data.type,
        ...combineListingEventProperties(data),
      },
    };

    this.sendEvent(eventPushData);
  }

  clickedApplyFilter(data: IParamFilterApply) {
    const eventName = 'shop_listing_filter_apply';

    const eventPushData = {
      event: eventName,
      parameters: {
        type: data.type,
        code: data.code,
        value: data.value,
        filter_method: data.filterMethod,
        price_method: data.priceMethod,
        price_to: data.priceTo,
        price_from: data.priceFrom,
        ...combineListingEventProperties(data),
      },
    };

    this.sendEvent(eventPushData);
  }

  clickedResetFilters(data: IParamFilterApply) {
    const eventName = 'shop_clicked_reset_filters';

    const eventPushData = {
      event: eventName,
      parameters: {
        type: data.type,
        filter_method: data.filterMethod,
        ...combineListingEventProperties(data),
      },
    };

    this.sendEvent(eventPushData);
  }

  clickedOnCategoryTag(data: IParamClickedOnCategoryTag) {
    const eventName = 'shop_listing_tag_filter_apply';
    const path = document.location.pathname;
    const pageCategory = getPageCategory(path);

    const parameters = {
      path,
      title: data.el.title,
      listing: pageCategory,
    };

    if (pageCategory === PageCategory.SEARCH_LISTING) {
      this.sendEvent({
        event: eventName,
        parameters: {
          ...parameters,
          search_term: data.searchTerm,
          query_id: data.queryID,
        },
      });

      return;
    }

    const categoryHierarchyArr = getCategoryHierarchyArr(data.treeCategory);
    const categoryHierarchyProps =
      getCategoryHierarchyProps(categoryHierarchyArr);
    const value = categoryHierarchyArr.length
      ? {
          value: [categoryHierarchyArr[categoryHierarchyArr.length - 1]],
        }
      : {};

    this.sendEvent({
      event: eventName,
      parameters: {
        ...parameters,
        ...categoryHierarchyProps,
        ...value,
      },
    });
  }

  changedListingLayout(data: IParamChangedListingLayout) {
    const eventName = 'shop_listing_layout_change';

    const eventPushData = {
      event: eventName,
      parameters: {
        value: data.value,
        ...combineListingEventProperties(data),
      },
    };

    this.sendEvent(eventPushData);
  }

  clickedListingSort(data: IBCatalogGridStoreType) {
    const eventName = 'shop_listing_sort_click';

    const eventPushData = {
      event: eventName,
      parameters: combineListingEventProperties(data),
    };

    this.sendEvent(eventPushData);
  }

  applyListingSort(data: IParamListingSort) {
    const eventName = 'shop_listing_sort_apply';

    const eventPushData = {
      event: eventName,
      parameters: {
        value: data.value,
        ...combineListingEventProperties(data),
      },
    };

    this.sendEvent(eventPushData);
  }

  clickedOnCategoryTab(data: IParamCategoryTab) {
    const eventName = 'shop_listing_tab_click';

    const eventPushData = {
      event: eventName,
      parameters: {
        category_name: data.categoryName,
        ...combineListingEventProperties(data),
      },
    };

    this.sendEvent(eventPushData);
  }

  splitCartBackdropEvent(data: IPharmacyBackdropData) {
    const eventName = data.eventName;

    const categoryHierarchyArr = getCategoryHierarchyArr(data.treeCategory);
    const categoryHierarchyProps =
      getCategoryHierarchyProps(categoryHierarchyArr);

    const parameters = {
      ssohash: getSessionId(),
      supplier: data.merchantName,
      supplier_code: data.merchantCode,
      type: data.isAddToCart ? 'new cart' : 'current cart',
      page_category: getPageCategory(document.location.pathname),
      path: document.location.pathname,
      ...categoryHierarchyProps,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedSearchForm(data: IParamSearchEventsParams) {
    const eventName = 'shop_search_form_view';

    const parameters = {
      has_history: data.hasSearchHistory,
      search_term: data.searchQuery,
      query_id: data.queryId,
      ssohash: data.ssohash,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedSearchHints(data: IParamSearchHintsEvents) {
    const eventName = data.isTestEvent
      ? 'test_shop_search_hint_viewed'
      : 'shop_search_hint_viewed';

    const categoryHierarchyArr = getCategoryHierarchyArr(data.treeCategory);
    const categoryHierarchyProps =
      getCategoryHierarchyProps(categoryHierarchyArr);

    const parameters = {
      ...categoryHierarchyProps,
      page_category: getPageCategory(document.location.pathname),
      search_term: data.searchTerm,
      query_id: data.queryId,
      ssohash: data.ssohash,
      hint_chips: data.hintChips,
      hint_chips_count: data.hintChips.length,
      hint_category: data.hintCategory,
      hint_category_count: data.hintCategory.length,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickOnSuggestion(data: IParamClickOnSuggestion) {
    const eventName = data.isTestEvent
      ? 'test_shop_search_hint_clicked'
      : 'shop_search_hint_clicked';

    const parameters = {
      ...combineSearchEventProperties(),
      has_history: data.hasSearchHistory,
      hint_type: data.suggestType,
      hint_term: data.term,
      search_term: data.searchQuery,
      query_id: data.queryId,
      ssohash: data.ssohash,
      position: data.position,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopSearchQueryStart(data: IParamSearchEventsParams) {
    const eventName = 'shop_search_query_start';

    const parameters = {
      ...combineSearchEventProperties(),
      has_history: data.hasSearchHistory,
      search_term: data.searchQuery,
      query_id: data.queryId,
      ssohash: data.ssohash,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRecommendation(data: IParamViewedRecommendation) {
    const categoryPageMap = {
      main: 'Home',
      product: 'Product',
      cart: 'Cart',
    };

    if (data.page === 'product') {
      return;
    }

    const eventPushData = {
      event: `shop_${data.page}_recommendation_view`,
      parameters: {
        category: categoryPageMap[data.page],
        block_id: data.object_id,
        block_type: data.entity,
        block_name: data.header,
        block_position: data.position,
        block_style: data.style,
      },
    };

    this.sendEvent(eventPushData);
  }

  viewedOrderPage(data: IParamOrderPage) {
    const eventName = 'shop_order_details';
    const param = getMyOrderDetailsParam(data);
    const innerSource = MobappMapper.innerSource();
    this.sendEvent({
      event: eventName,
      parameters: {
        ...param,
        ...innerSource,
        source: getFromUrl.source(),
        ssohash: data.ssohash,
        product: 'shop',
      },
    });
  }

  viewedProductsOutOfStockAlert(data: IParamOrderPage) {
    const eventName = 'shop_products_out_of_stock_alert';
    const innerSource = MobappMapper.innerSource();
    const parameters = {
      path: [document.location.pathname],
      ...innerSource,

      order_id: data.orderId,
      suborder_id: data.orderId,

      current_status: data.currentStatus,
      cart_quantity: MobappMapper.cartQuantity(data.entries),
      cart_size: data.entries?.length,

      delivery_cost: data.totalPrice.delivery,
      subtotal: data.totalPrice.price,
      total: data.totalPrice.delivery + data.totalPrice.price,

      supplier: data.merchant.name,
      supplier_id: data.entries.filter((entry) => entry.merchantId)[0]
        ?.merchantId,

      payment_method: data.paymentData.type,
      delivery_method: data.delivery.type,

      cart_quantity_missing: data.entries.filter((entry) => entry.outOfStock)
        .length,
      backdrop: 'out_of_stock',
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRepeatOrderProductsAdded(data: IParamOrderPage) {
    const eventName = 'shop_repeat_order_products_added';
    const innerSource = MobappMapper.innerSource();
    const categoryHierarchyProps = getMultipleEntriesCategoryHierarchyProps(
      data.entries
    );

    const parameters = {
      path: [document.location.pathname],
      ...innerSource,
      ...categoryHierarchyProps,

      order_id: data.orderId,
      suborder_id: data.orderId,

      current_status: data.currentStatus,
      cart_quantity: MobappMapper.cartQuantity(data.entries),
      cart_size: data.entries?.length,

      delivery_cost: data.totalPrice.delivery,
      subtotal: data.totalPrice.price,
      total: data.totalPrice.delivery + data.totalPrice.price,

      supplier: data.merchant.name,
      supplier_id: data.entries.filter((entry) => entry.merchantId)[0]
        ?.merchantId,

      payment_method: data.paymentData.type,
      delivery_method: data.delivery.type,

      cart_quantity_missing: data.entries.filter((entry) => entry.outOfStock)
        .length,
      cart_quantity_added: data.entries.filter((entry) => !entry.outOfStock)
        .length,

      backdrop: data.backdropParameter,
      product_id: data.entries.map((entry) => entry.productCode),
    } as any;

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRepeatOrderMerchantBackdrop(data: IParamOrderPage) {
    const eventName = 'test_shop_repeat_order_merchant_backdrop_view';
    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.entries[0].category,
      true
    );

    const parameters = {
      suppliers_count: data.merchantOffers.offersCount,
      product_id: data.entries[0].productCode,
      ...categoryHierarchyProps,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRepeatOrderAnalogGoodsBackdrop(data: IParamOrderPage) {
    const eventName = 'test_shop_repeat_order_similar_goods_backdrop_view';
    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.entries[0].category,
      true
    );

    const parameters = {
      path: document.location.pathname,
      cart_quantity: data.cartQuantity,
      product_id: data.entries[0].productCode,
      ...categoryHierarchyProps,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRepeatOrderProductsAddedAction(data: IParamOrderPage) {
    const eventName = 'shop_repeat_order_products_added_action';
    const innerSource = MobappMapper.innerSource();
    const parameters = {
      path: [document.location.pathname],
      ...innerSource,

      order_id: data.orderId,
      suborder_id: data.orderId,

      current_status: data.currentStatus,
      cart_quantity: MobappMapper.cartQuantity(data.entries),
      cart_size: data.entries?.length,

      delivery_cost: data.totalPrice.delivery,
      subtotal: data.totalPrice.price,
      total: data.totalPrice.delivery + data.totalPrice.price,

      supplier: data.merchant.name,
      supplier_id: data.entries.filter((entry) => entry.merchantId)[0]
        ?.merchantId,

      payment_method: data.paymentData.type,
      delivery_method: data.delivery.type,

      cart_quantity_missing: data.entries.filter((entry) => entry.outOfStock)
        .length,
      cart_quantity_added: data.entries.filter((entry) => !entry.outOfStock)
        .length,
      action: 'proceed',
    } as any;

    parameters.backdrop = [
      parameters.cart_quantity_missing ? 'missing' : 'all_in_stock',
    ];

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedProductPage(data: IParamViewedProductPage) {
    const eventName = 'shop_product';
    const productProperties: any = combineProductEventProperties(data);
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);
    const crossSellParams =
      JSON.parse(this.m.sessionStorage.getValue(crossSellAnalyticName)) || null;

    if (crossSellParams) {
      this.m.sessionStorage.removeValue(crossSellAnalyticName);
    }

    const parameters = {
      ...productProperties,
      ...crossSellParams,
      has_configurator: !!hasConfigurator,
      promo,
      ssohash: data.ssohash,
      started_by: data.startedBy,
      is_discount: data.discountType,
      test_discount_amount: data.discountAmount || false,
      seller_rating: data.sellerRating,
      ...combineMarketingEventProperties(),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedProductCta(data: IParamClickedProductCta) {
    const eventName = 'shop_product_cta_click';
    const parameters: any = combineProductEventProperties(data);
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);

    parameters.has_configurator = !!hasConfigurator;
    parameters.promo = promo;
    parameters.cta_value = data.ctaValue;

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedProductMerchantSelect(data: IParamViewedProductMerchantSelect) {
    const eventName = 'shop_product_merchant_select';
    const parameters: any = combineProductEventProperties(data);
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);

    parameters.has_configurator = !!hasConfigurator;
    parameters.promo = promo;
    parameters.cta_value = data.ctaValue;
    parameters.ssohash = data.ssohash;
    parameters.started_by = data.startedBy;

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedOfferMerchantWithDiscount(data: IParamClickedProductSelectOffer) {
    const eventName = 'test_merchant_with_discount_view';
    const supplier = getOfferBaseParams(data.offer);
    const product = combineProductEventProperties(data);
    const pageCategory = getPageCategory(window.location.pathname);

    const parameters: any = {
      ...product,
      ...supplier,
      supplier_position: data.position,
      started_by: data.startedBy,
      page_category: pageCategory,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedProductSelectOffer(data: IParamClickedProductSelectOffer) {
    const eventName = 'shop_product_select_offer_click';
    const product = combineProductEventProperties(data);
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);
    const delivery = getBaseDeliveryOptions(data.offer.deliveryOptions);
    const supplier = getOfferBaseParams(data.offer);
    const pageCategory = getPageCategory(window.location.pathname);
    const parameters: any = {
      ...product,
      ...delivery,
      ...supplier,
      supplier_position: data.position,
      has_configurator: !!hasConfigurator,
      promo,
      started_by: data.startedBy,
      page_category: pageCategory,
      seller_rating: data.sellerRating,
      test_discount_amount: data.offer?.discountAmount || false,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedProductQuantityBackdrop(data: IParamViewedProductQuantityBackdrop) {
    const eventName = 'shop_product_quantity_backdrop';
    const product = combineProductEventProperties(data);
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);
    const delivery = getBaseDeliveryOptions(data.offer.deliveryOptions);
    const supplier = getOfferBaseParams(data.offer);
    const pageCategory = getPageCategory(window.location.pathname);
    const parameters: any = {
      ...product,
      ...delivery,
      ...supplier,
      supplier_position: data.position,
      has_configurator: !!hasConfigurator,
      promo,
      page_category: pageCategory,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedListingProductMerchants(data: IParamViewedListingProductMerchants) {
    const eventName = 'shop_listing_product_merchants_view';
    const baseParams = getBaseParams();
    const categoryHierarchyProps = getCategoryHierarchyProps(data.category);
    const filtersDate = {
      available_filters: data.filters.map(
        (filter: IFiltersDate) => filter.title
      ),
    };

    const parameters = {
      ...baseParams,
      ...categoryHierarchyProps,
      ...filtersDate,
      search_term: data.searchTerm,
      query_id: data.queryId,
      result_count: [String(data.resultCount)],
      ssohash: data.ssohash,
      started_by: data.startedBy,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedDeliveryFiltersApply(data: IParamViewedDeliveryFiltersApply) {
    const eventName = 'shop_delivery_filters_apply';
    const baseParams = getBaseParams();
    const categoryHierarchyProps = getCategoryHierarchyProps(data.category);
    const filtersDate = {
      available_filters: data.filters.map(
        (filter: IFiltersDate) => filter.title
      ),
    };
    const applyFilter = { apply_filter: [data.applyFilter] };

    const parameters = {
      ...baseParams,
      ...categoryHierarchyProps,
      ...filtersDate,
      ...applyFilter,
      result_count: [String(data.resultCount)],
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedDeliveryFiltersReset(data: IParamViewedDeliveryFiltersReset) {
    const eventName = 'shop_delivery_filters_reset';
    const baseParams = getBaseParams();
    const categoryHierarchyProps = getCategoryHierarchyProps(data.category);
    const filtersDate = {
      available_filters: data.filters.map(
        (filter: IFiltersDate) => filter.title
      ),
    };
    const droppedFilter = { dropped_filter: [data.droppedFilter] };

    const parameters = {
      ...baseParams,
      ...categoryHierarchyProps,
      ...filtersDate,
      ...droppedFilter,
      result_count: [String(data.resultCount)],
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedProductOffersPage(data: IParamViewedProductOffersPage) {
    const eventName = 'shop_product_offers';
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);

    const parameters = {
      ...combineProductEventProperties(data),
      has_configurator: !!hasConfigurator,
      promo,
      supplier: data.product?.supplierOffers.map(
        (offer) => offer.supplier.name
      ),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedSellersOffers() {
    const eventName = 'shop_suppliers_block_view';
    const parameters = {};

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedTryout(data: IParamViewedProductPage) {
    const eventName = 'shop_fitting_info';
    const parameters = combineProductEventProperties(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  startViewingProductReview(data: IParamViewedProductReview) {
    const eventName = 'shop_product_review_start';
    const parameters = combineProductReviewsEventProperties(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  finishViewingProductReview(data: IParamViewedProductReview) {
    const eventName = 'shop_product_review_finish';
    const parameters = combineProductReviewsEventProperties(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedProductReviews(data: IParamViewedProductPage) {
    const eventName = 'shop_product_reviews';
    const parameters: any = combineProductEventProperties(data);
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);

    parameters.has_configurator = !!hasConfigurator;
    parameters.promo = promo;
    parameters.started_by = getFromUrl.startedBy();

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedProductSpecifications(data: IParamViewedProductPage) {
    const eventName = 'shop_product_specs';
    const parameters: any = combineProductEventProperties(data);
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);

    parameters.is_discount = data.discountType;
    parameters.has_configurator = !!hasConfigurator;
    parameters.promo = promo;

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedProductDescription(data: IParamViewedProductPage) {
    const eventName = 'shop_product_description';
    const globalPromotion = data.promo;
    const hasConfigurator = data.hasConfigurator;
    const { promo = null } = getProductPromo(globalPromotion);

    const parameters = {
      ...combineProductEventProperties(data),
      promo,
      has_configurator: !!hasConfigurator,
      is_discount: data.discountType,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedProductParameterChange(data: IParamClickedProductParameterChange) {
    const eventName = 'shop_product_parameter_change';
    const globalPromotion = data.promo;
    const { promo = null } = getProductPromo(globalPromotion);
    const parameters = {
      ...combineProductEventProperties(data),
      promo,
      configurator_type: data.configuratorType,
      configurator_value: data.configuratorValue,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedSizeBackdrop(data: IParamViewedProductPage) {
    const eventName = 'shop_product_size_table_view';
    const product = combineProductEventProperties(data);
    const globalPromotion = data.promo;
    const { promo = null } = getProductPromo(globalPromotion);
    const hasConfigurator = data.hasConfigurator;

    const parameters = {
      ...product,
      promo,
      has_configurator: !!hasConfigurator,
      started_by: data.startedBy,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedFastBuyNow(data: IParamViewedFastBuyNow) {
    const eventName = 'shop_quick_buy_now_view';

    if (!data.cart) {
      /* tslint:disable-next-line */
      console.error('MobappMapper: viewedFastBuyNow - no cart');
      return;
    }

    const cartParams = getCartParams(data.cart);

    const parameters = {
      bonus_amount: data.bonusAmount,
      delivery_method: data.deliveryMethod,
      checkout_method: cartParams.checkout_method,
      delivery_price: data.deliveryPrice,
      subtotal_price: cartParams.subtotal_price,
      total_price: cartParams.total_price,
      path: data.path,
      supplier: data.cart?.supplier?.name,
      category_level_1: cartParams.category_level_1,
      category_level_2: cartParams.category_level_2,
      category_level_3: cartParams.category_level_3,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCheckoutOrderConfirmPage(data: IParamViewedCheckoutOrderConfirmPage) {
    const eventName = 'shop_product_transaction';

    if (!data.transaction.lineItems) {
      /* tslint:disable-next-line */
      console.error(
        'MobappMapper: viewedCheckoutOrderConfirmPage - no lineItems in transaction'
      );
      return;
    }

    const cartTransactionParams = getTransactionCartParams(data.transaction);

    const isCartMethod =
      data.transaction.checkoutMethod === CheckoutMethod.cart;

    const transactionLineItems = isCartMethod
      ? data.transaction.lineItems.filter((item: ITransactionLineItem) =>
          isProductSelectedInCart(item.product.id, item.supplier.id)
        )
      : data.transaction.lineItems;

    transactionLineItems.forEach((item: ITransactionLineItem) => {
      const categoryHierarchyProps = getCategoryHierarchyProps(
        item.product?.category
      );
      const productInfo = getTransactionProductInfo(item.product);

      const parameters = {
        ...cartTransactionParams,
        amount: item.amount,
        time: item.time,
        final_screen: data.finalScreen,
        suborder_id: item.subOrderId,
        delivery_method: item.shippingMethod,
        delivery_provider: item.shippingProvider,
        delivery_cost: item.shippingCost,
        supplier_id: item.supplier?.id,
        supplier: item.supplier?.name,
        delivery_day: item.supplier?.delivery_day,
        delivery_timeslot: item.supplier?.delivery_timeslot,
        path: document.location.pathname,
        ...productInfo,
        ...categoryHierarchyProps,
      };

      this.sendEvent({
        event: eventName,
        parameters,
      });
    });
  }

  viewedCheckoutDecisionRejectedPage(data: IParamViewedCheckoutDecisionPage) {
    if (data.paymentType !== PaymentStepTypes.credit) {
      return;
    }

    data.transaction = getTransactionFromSessionCart(data.orders);
    data.finalScreen = TransactionFinalScreenType.creditRejected;

    this.viewedCheckoutOrderConfirmPage(data);
  }

  viewedCheckoutDecisionNewSumPage(data: IParamViewedCheckoutDecisionPage) {
    data.transaction = getTransactionFromSessionCart(data.orders);
    data.finalScreen = TransactionFinalScreenType.creditAlternativeBySum;

    this.viewedCheckoutOrderConfirmPage(data);
  }

  viewedCheckoutDecisionNewConditionsPage(
    data: IParamViewedCheckoutDecisionPage
  ) {
    data.transaction = getTransactionFromSessionCart(data.orders);
    data.finalScreen = TransactionFinalScreenType.creditAlternativeByTerm;

    this.viewedCheckoutOrderConfirmPage(data);
  }

  viewedCheckoutDecisionApprove(data: IParamViewedCheckoutDecisionPage) {
    data.transaction = getTransactionFromSessionCart(data.orders);
    data.finalScreen =
      data.type === ApproveSignType.SIGN
        ? TransactionFinalScreenType.preApprovedNeedECP
        : TransactionFinalScreenType.preApprovedNeedConfirmId;

    this.viewedCheckoutOrderConfirmPage(data);
  }

  viewedCartPage(data: any) {
    const eventName = 'shop_cart';

    const cartParams = getCartParams(data.cart);

    const magnumSubtotalPrice = data.cart.lineItems?.reduce(
      (sum, lineItem) =>
        lineItem.supplier.id === 'Magnum' ? sum + lineItem.subtotal : sum,
      0
    );

    const startedFrom = getStartedFrom();

    const parameters = {
      path: document.location.pathname,
      ...cartParams,
      suborder_count: data.suborderCount,
      delivery_price:
        data.sumCarts >= CONSTANTS.minPriceForFreeDelivery ? 0 : undefined,
      cart_quantity_price_change: data.productsWithChangedPrice,
      cart_quantity_not_available: data.productsOutOfStock,
      cart_quantity_discount: data.cartQuantityDiscount ?? 0,
      magnum_subtotal_price: magnumSubtotalPrice,
      cart_quantity_other_supplier: data.otherMerchantOffersCount,
      ...startedFrom,
      type: data.type
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCheckoutPage(data: any) {
    const eventName = 'shop_checkout_delivery_method';

    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.deliveryOptions
    );
    const availableDeliveryMethod = getAvailableDeliveryMethod(
      data.deliveryOptions
    );

    const parameters = {
      path: document.location.pathname,
      suborder_count: data.suborderCount,
      default_delivery_method: data.defaultDeliveryMethods,
      ...contExpressDeliveryOption(data.deliveryOptions),
      ...cartParams,
      ...deliveryOptionsParams,
      ...availableDeliveryMethod,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedCheckoutPay(data: any) {
    const eventName = 'shop_checkout_pay_click';
    const cartParams = getCartParams(data.sessionCart);

    const parameters = {
      latitude: data.userCoords?.[0] ?? null,
      longitude: data.userCoords?.[1] ?? null,
      cart_size: cartParams.cart_size,
      category_level_1: cartParams.category_level_1,
      category_level_2: cartParams.category_level_2,
      category_level_3: cartParams.category_level_3,
      delivery_method: data.selectedDeliveryTypes,
      subtotal_price: cartParams.subtotal_price,
      total_price: cartParams.total_price,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  selectedDeliveryMethod(data: any) {
    const eventName = 'shop_checkout_delivery_method_select';

    this.sendEvent({
      event: eventName,
      parameters: getCartInDeliveryParams(
        data.cartItem,
        data.cart,
        data.deliveryOption,
        data.cartItemDeliveryOptions
      ),
    });
  }

  viewedCheckoutPaymentPage(data: any) {
    const eventName = 'shop_checkout_payment_method';

    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.delivery?.deliveryOptions
    );

    const parameters = {
      bonus_amount: data.bonus,
      delivery_method: getCheckoutDeliveryMethod(data.cart.lineItems),
      path: document.location.pathname,
      suborder_count: data.delivery?.carts?.length,
      has_balance: data.hasBalance,
      ...cartParams,
      ...deliveryOptionsParams,
      delivery_price:
        data.deliveryPrice || deliveryOptionsParams.delivery_price,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCheckoutAlertError(data: any) {
    const eventName = 'shop_checkout_alert_error_view';
    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.deliveryOptions.deliveryOptions
    );
    const availableDeliveryMethod = getAvailableDeliveryMethod(
      data.deliveryOptions.deliveryOptions
    );
    const parameters = {
      ...cartParams,
      ...deliveryOptionsParams,
      ...availableDeliveryMethod,
      alert_type: 'custom',
      path: document.location.pathname,
      page_category: getPageCategory(window.location.pathname),
      suborder_count: data.deliveryOptions.suborderCount,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedNearbyPostomatToDeliveryPointBackdrop(data: any) {
    const eventName = 'shop_checkout_nearby_postomat_view';
    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.deliveryOptions.deliveryOptions
    );
    const availableDeliveryMethod = getAvailableDeliveryMethod(
      data.deliveryOptions.deliveryOptions
    );
    const parameters = {
      ...cartParams,
      ...deliveryOptionsParams,
      ...availableDeliveryMethod,
      path: document.location.pathname,
      page_category: getPageCategory(window.location.pathname),
      suborder_count: data.deliveryOptions.suborderCount,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedAcceptNearbyPostomat(data: any) {
    const eventName = 'shop_checkout_nearby_postomat_accept';
    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.deliveryOptions.deliveryOptions
    );
    const availableDeliveryMethod = getAvailableDeliveryMethod(
      data.deliveryOptions.deliveryOptions
    );
    const parameters = {
      ...cartParams,
      ...deliveryOptionsParams,
      ...availableDeliveryMethod,
      path: document.location.pathname,
      page_category: getPageCategory(window.location.pathname),
      suborder_count: data.deliveryOptions.suborderCount,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedRejectNearbyPostomat(data: any) {
    const eventName = 'shop_checkout_nearby_postomat_reject';
    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.deliveryOptions.deliveryOptions
    );
    const availableDeliveryMethod = getAvailableDeliveryMethod(
      data.deliveryOptions.deliveryOptions
    );
    const parameters = {
      ...cartParams,
      ...deliveryOptionsParams,
      ...availableDeliveryMethod,
      path: document.location.pathname,
      page_category: getPageCategory(window.location.pathname),
      suborder_count: data.deliveryOptions.suborderCount,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedMapNearbyPostomat(data: any) {
    const eventName = 'shop_checkout_nearby_postomat_map';
    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.deliveryOptions.deliveryOptions
    );
    const availableDeliveryMethod = getAvailableDeliveryMethod(
      data.deliveryOptions.deliveryOptions
    );
    const parameters = {
      ...cartParams,
      ...deliveryOptionsParams,
      ...availableDeliveryMethod,
      path: document.location.pathname,
      page_category: getPageCategory(window.location.pathname),
      suborder_count: data.deliveryOptions.suborderCount,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedCheckoutPaymentButton(data: any) {
    const eventName = 'shop_checkout_payment_cta_click';

    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.delivery?.deliveryOptions
    );
    const isCreditPayment =
      data.currentPayment.typeCase === PaymentStepTypes.credit;

    const parameters = {
      payment_method: data.currentPayment.id,
      credit_term: isCreditPayment ? data.selectedLoanId : '',
      credit_monthly_price: isCreditPayment
        ? data.currentPayment.data?.loanSums?.find(
            (s) => s.duration === data.selectedLoanId
          )?.priceCredit
        : '',
      bonus_spent: data.bonusSpent || 0,
      bonus_amount: data.bonus,
      delivery_method: getCheckoutDeliveryMethod(data.cart.lineItems),
      path: document.location.pathname,
      suborder_count: data.delivery?.carts?.length,
      has_balance: data.hasBalance,
      ...cartParams,
      ...deliveryOptionsParams,
      delivery_price:
        data.deliveryPrice || deliveryOptionsParams.delivery_price,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCheckoutPostomatList(data: IParamCheckoutPostomatData) {
    const eventName = 'shop_checkout_postomat_list_view';

    this.sendEvent({
      event: eventName,
      parameters: getCheckoutPostomatParams(data),
    });
  }

  clickedCheckoutPostomat(data: IParamCheckoutPostomatData) {
    const eventName = 'shop_checkout_postomat_click';

    const postomatParams = getCheckoutPostomatParams(data);
    const parameters = {
      ...postomatParams,
      clicked_from: data.clickedFrom,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedCheckoutPostomatAddress(data: IParamCheckoutPostomatData) {
    const eventName = 'shop_checkout_postomat_address_click';

    this.sendEvent({
      event: eventName,
      parameters: getCheckoutPostomatParams(data),
    });
  }

  clickedCheckoutPostomatPickup(data: IParamCheckoutPostomatData) {
    const eventName = 'shop_checkout_postomat_pickup_click';

    this.sendEvent({
      event: eventName,
      parameters: getCheckoutPostomatParams(data),
    });
  }

  viewedCheckoutPostomatBackdrop(data: IParamCheckoutPostomatData) {
    const eventName = 'shop_checkout_postomat_backdrop_view';

    this.sendEvent({
      event: eventName,
      parameters: getCheckoutPostomatParams(data),
    });
  }

  clickedCheckoutPostomatBackdrop(data: IParamCheckoutPostomatData) {
    const eventName = 'shop_checkout_postomat_backdrop_click';

    const postomatParams = getCheckoutPostomatParams(data);
    const parameters = {
      ...postomatParams,
      action: data.action,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCreditApplicationForm(data: any) {
    const eventName = 'shop_credit_application_form_view';

    const cartParams = getCartParams(data.cart);
    const bonusAmount = data.payment?.bonus?.accumulated;
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.delivery?.deliveryOptions
    );
    const bonusSpent = data.payment.useBonus
      ? bonusAmount >= data.payment.price
        ? data.payment.price
        : bonusAmount
      : 0;

    const parameters = {
      ...cartParams,
      order_id: data.cart.id,
      ...deliveryOptionsParams,
      payment_method: data.cart.payment_method,
      bonus_spent: bonusSpent,
      bonus_amount: bonusAmount,
      delivery_method: getCheckoutDeliveryMethod(data.cart.lineItems),
      path: document.location.pathname,
      suborder_count: data.delivery?.carts?.length,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCheckoutTrustedPersonPage(data) {
    const eventName = 'shop_credit_application_trusted_persons';

    const cartParams = getCartParams(data.cart);
    const bonusAmount = data.payment?.bonus?.accumulated;
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.delivery?.deliveryOptions
    );
    const bonusSpent = data.payment.useBonus
      ? bonusAmount >= data.payment.price
        ? data.payment.price
        : bonusAmount
      : 0;

    const parameters = {
      ...cartParams,
      order_id: data.cart.id,
      ...deliveryOptionsParams,
      payment_method: data.cart.payment_method,
      bonus_spent: bonusSpent,
      bonus_amount: bonusAmount,
      delivery_method: getCheckoutDeliveryMethod(data.cart.lineItems),
      path: document.location.pathname,
      suborder_count: data.delivery?.carts?.length,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCreditApprovalInProgress(data: any) {
    const eventName = 'shop_credit_approval_in_progress';

    const bonusAmount = data.payment?.bonus?.accumulated;
    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.delivery.deliveryOptions
    );
    const bonusSpent = data.payment.useBonus
      ? bonusAmount >= data.payment.price
        ? data.payment.price
        : bonusAmount
      : 0;

    const parameters = {
      payment_method: data.cart.paymentMethod,
      bonus_spent: bonusSpent,
      bonus_amount: bonusAmount,
      delivery_method: getCheckoutDeliveryMethod(data.cart.lineItems),
      path: document.location.pathname,
      suborder_count: data.delivery.carts?.length,
      has_balance:
        data.payment.paymentOptions?.find(
          (o) => o.typeCase === PaymentStepTypes.gold
        )?.data?.means >= data.payment.price,
      ...cartParams,
      ...deliveryOptionsParams,
      delivery_price:
        data.payment.deliveryPrice || deliveryOptionsParams.delivery_price,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedShopCardPayment(data: any) {
    const eventName = 'shop_card_payment_in_progress';

    const bonusAmount = data.payment?.bonus?.accumulated;
    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.delivery.deliveryOptions
    );
    const bonusSpent = data.payment.useBonus
      ? bonusAmount >= data.payment.price
        ? data.payment.price
        : bonusAmount
      : 0;

    const parameters = {
      payment_method: data.cart.paymentMethod,
      bonus_spent: bonusSpent,
      bonus_amount: bonusAmount,
      delivery_method: getCheckoutDeliveryMethod(data.cart.lineItems),
      path: document.location.pathname,
      suborder_count: data.delivery.carts?.length,
      has_balance:
        data.payment.paymentOptions?.find(
          (o) => o.typeCase === PaymentStepTypes.gold
        )?.data?.means >= data.payment.price,
      ...cartParams,
      ...deliveryOptionsParams,
      delivery_price:
        data.payment.deliveryPrice || deliveryOptionsParams.delivery_price,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedPaymentBalanceAlert(data: any) {
    const eventName = 'shop_checkout_payment_balance_alert';

    const cartParams = getCartParams(data.cart);
    const deliveryOptionsParams = getDeliveryOptionsParams(
      data.delivery?.deliveryOptions
    );
    const isCreditPayment =
      data.currentPayment.typeCase === PaymentStepTypes.credit;

    const parameters = {
      payment_method: data.cart.paymentMethod,
      credit_term: isCreditPayment ? data.selectedLoanId : '',
      credit_monthly_price: isCreditPayment
        ? data.currentPayment.data?.loanSums?.find(
            (s) => s.duration === data.selectedLoanId
          )?.priceCredit
        : '',
      bonus_spent: data.bonusSpent || 0,
      bonus_amount: data.bonus,
      delivery_method: getCheckoutDeliveryMethod(data.cart.lineItems),
      path: document.location.pathname,
      suborder_count: data.delivery?.carts?.length,
      has_balance: data.hasBalance,
      ...cartParams,
      ...deliveryOptionsParams,
      delivery_price:
        data.deliveryPrice || deliveryOptionsParams.delivery_price,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCrossSellBackdrop(data: IParamCrossSellBackdropData) {
    const eventName = 'shop_cross_sell_backdrop_view';

    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.mainProduct?.category
    );

    const crossSellData = getPcmCrossSellData(data);

    const parameters = {
      ...categoryHierarchyProps,
      ...crossSellData,
      page_category: getPageCategory(window.location.pathname),
      ssohash: data.ssohash,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  closedCrossSellBackdrop(data: IParamCrossSellBackdropData) {
    const eventName = 'shop_cross_sell_backdrop_close';

    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.product?.category
    );

    const crossSellData = getPcmCrossSellData(data);

    const parameters = {
      ...categoryHierarchyProps,
      ...crossSellData,
      page_category: getPageCategory(window.location.pathname),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedCrossSellItemCard(data: IParamCrossSellBackdropData) {
    const eventName = 'shop_cross_sell_backdrop_click';

    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.product?.category
    );
    const baseParams = getBaseParams();
    const productInfo = getProductInfo(data.product);
    const productPromo = getProductPromo(data.product?.promo);
    const startedFrom = getStartedFrom();
    const crossSellData = getPcmCrossSellData(data);

    const parameters = {
      ...categoryHierarchyProps,
      ...baseParams,
      ...productInfo,
      ...startedFrom,
      ...productPromo,
      ...crossSellData,
      promo: productPromo?.promo,
      product_type: data.product?.unit.type.toLowerCase(),
      referrer: getFromUrl.referrer(),
      ref: getFromUrl.ref(),
      query_id: getFromUrl.queryId(),
      ssohash: data.ssohash,
    };

    this.m.sessionStorage.saveValue({
      name: crossSellAnalyticName,
      value: JSON.stringify(crossSellData),
    });

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  addedProductInCart(data: IParamChangeProductInCart) {
    const eventName = 'shop_add_to_cart_click';

    const baseParams = getBaseParams();
    const productInfo = getProductInfo(data.product);
    const offerBaseParams = getOfferBaseParams(data.offer);
    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.product?.category
    );

    const promo =
      data.product?.promo || window.itemPageStore?.offers.data.globalPromotion;
    const productPromo = getProductPromo(promo);
    const productType =
      (data.product?.unit && productUnitType[data.product.unit.type]) || '';
    const adInfo = getAdInfo(data.adsSupplierId);
    const blockData = getPcmBlockData(data.blockData);
    const crossSellData = getPcmCrossSellData(data.crossSellData);

    const parameters = {
      ...baseParams,
      ...categoryHierarchyProps,
      ...productInfo,
      ...offerBaseParams,
      ...productPromo,
      ...adInfo,
      ...blockData,
      ...crossSellData,
      quantity: data.quantity,
      offers_quantity: data.total,
      ads_unique_id: data.adsUniqueId,
      listing_position: data.listingPosition,
      query_id: getFromUrl.queryId() || data.queryId,
      referrer: getFromUrl.referrer(),
      product_type: productType,
      ssohash: data.ssohash,
      supplier_position: data.positionId,
      source: data.source,
      started_by: data.startedBy,
      seller_rating: data.sellerRating,
      is_discount: !!data.offer?.discountAmount,
      test_discount_amount: data.offer?.discountAmount || false,
      from_backdrop: data.fromBackdrop,
      source_sku: data.product?.sourceSku,
      ...(data.offersStoreData // only in item page & sellers page SN-2091
        ? combineOffersFiltersEventProperties(
            data as IOffersFiltersAnalyticParams
          )
        : []),
      search_term:
        getFromUrl.searchQuery() ||
        data.searchQuery ||
        getSearchTermFromPrevSearchPage(),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOtherMerchantSelect(data: any) {
    const eventName = 'shop_cart_select_another_supplier_click';

    const parameters = {
      ...getProductInCartParams(data),
      supplier_name_not_in_stock: data.product.merchant,
      supplier_id_not_in_stock: data.product.merchantId,
      offers_quantity: data.offersQuantity,
      price: data.bestPrice,
      credit_monthly_price: String(data.creditMonthlyPrice ?? '')?.replace(
        /\D+/g,
        ''
      ),
      kd_offers_count: data.kaspiDeliveryOffersCount,
      suborder_count: data.cartGroupsLength,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedAnalogGoodsSelect(data: any) {
    const eventName = 'shop_view_similar_goods_click';
    const magnum_subtotal_price = data.cart.lineItems?.reduce(
      (sum: number, lineItem) =>
        lineItem.supplier.id === 'Magnum' ? sum + lineItem.subtotal : sum,
      0
    );

    const parameters = {
      ...getProductWithOneCategoryInCartParams(data),
      suborder_count: data.cartGroupsLength,
      cart_quantity_price_change: data.productsWithChangedPrice,
      cart_quantity_not_available: data.productsOutOfStock,
      cart_quantity_discount: data.cartQuantityDiscount ?? 0,
      magnum_subtotal_price,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedAnalogGoodsBackdrop(data: any) {
    const eventName = 'shop_similar_goods_backdrop_view';
    const magnum_subtotal_price = data.cart.lineItems?.reduce(
      (sum, lineItem) =>
        lineItem.supplier.id === 'Magnum' ? sum + lineItem.subtotal : sum,
      0
    );

    const parameters = {
      ...getProductWithOneCategoryInCartParams(data),
      suborder_count: data.cartGroupsLength,
      cart_quantity_price_change: data.productsWithChangedPrice,
      cart_quantity_not_available: data.productsOutOfStock,
      cart_quantity_discount: data.cartQuantityDiscount ?? 0,
      magnum_subtotal_price,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCartOutOfStockButton(data: any) {
    const eventName = 'shop_cart_not_available_view';
    const magnum_subtotal_price = data.cart.lineItems?.reduce(
      (sum, lineItem) =>
        lineItem.supplier.id === 'Magnum' ? sum + lineItem.subtotal : sum,
      0
    );

    const parameters = {
      ...getProductWithOneCategoryInCartParams(data),
      suborder_count: data.cartGroupsLength,
      cart_quantity_price_change: data.productsWithChangedPrice,
      cart_quantity_not_available: data.productsOutOfStock,
      cart_quantity_discount: data.cartQuantityDiscount ?? 0,
      magnum_subtotal_price,
      button: data.button,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedCartProductQuantityIncrease(data: IParamChangeProductInCart) {
    const eventName = 'shop_cart_product_quantity_increase';
    const innerSource = MobappMapper.innerSource();
    const parameters = {
      ...getProductWithOneCategoryInCartParams(data),
      ...innerSource,
      source: 'app',
      product: 'shop',
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedCartProductQuantityDecrease(data: IParamChangeProductInCart) {
    const eventName = 'shop_cart_product_quantity_decrease';
    const { inner_source } = MobappMapper.innerSource();

    const parameters = {
      ...getProductWithOneCategoryInCartParams(data),
      inner_source,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  removedProductFromCart(data: IParamChangeProductInCart) {
    const eventName = 'shop_cart_product_remove';
    const innerSource = MobappMapper.innerSource();
    const parameters = {
      ...getProductWithOneCategoryInCartParams(data),
      ...innerSource,
      selection_apply: data.isSelect,
      delete_quantity: data.product.quantity,
      started_by: data.startedBy,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  removedNotAvailableProductFromCart(data: IParamChangeProductInCart) {
    const eventName = 'shop_cart_product_not_available_delete';
    const merchants = getMerchantsInfo(data.outOfStockProducts);
    const parameters = {
      ...getProductWithOneCategoryInCartParams(data),
      supplier_name_not_in_stock: merchants.merchantsNames,
      supplier_id_not_in_stock: merchants.merchantsIds,
      source: 'app',
      started_from: 'services',
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRemoveProductPopup(data: IParamChangeProductInCart) {
    const eventName = 'shop_cart_remove_alert';
    const innerSource = MobappMapper.innerSource();
    const parameters = {
      ...getProductInCartParams(data),
      ...innerSource,
      selection_apply: data.isSelect,
      product: 'shop',
      source: 'app',
      delete_quantity: data.product.quantity,
      started_by: data.startedBy,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedSelectProductsToContinuePopup(data: IParamCartAlertView) {
    const eventName = 'shop_cart_alert_view';

    const parameters = {
      ...getProductInCartParams(data),
      text: data.text,
      suborder_count: data.groupsLength,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedMerchantInCart(data: IParamCartMerchantClick) {
    const eventName = 'shop_cart_supplier_click';

    const parameters = {
      ...getProductInCartParams(data),
      suborder_count: data.groupsLength,
      supplier: data.merchantName,
      supplier_id: data.merchantId,
      cart_quantity_supplier:
        data.cart.lineItems?.filter(
          (item) => item.supplier.id === data.merchantId
        )?.length || 0,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedContinueButtonInCart(data: any) {
    const eventName = 'shop_cart_continue_button_click';

    const selectedUniqueMerchantsCount = Array.from(
      new Set(data.selectedProducts.map(({ merchantId }) => merchantId))
    );

    const parameters = {
      ...getProductInCartParams(data),
      suborder_count: data.groupsLength,
      selected_items: data.selectedProducts.length,
      selected_merchants: selectedUniqueMerchantsCount,
      cart_quantity_price_change: data.productsWithChangedPrice,
      cart_quantity_not_available: data.productsOutOfStock,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedRemoveSelectedProductsFromCart(data: IParamRemoveSelectedFromCart) {
    const eventName = 'shop_cart_delete_selected_click';

    const changedProductsCategoryLevels = getChangedCategoryLevels(
      data.removedProducts
    );

    const parameters = {
      ...getProductInCartParams(data),
      ...changedProductsCategoryLevels,
      suborder_count: data.groupsLength,
      suppliers_id_remove: data.removedMerchants.map((merchant) => merchant.id),
      suppliers_name_remove: data.removedMerchants.map(
        (merchant) => merchant.name
      ),
      quantity_remove: data.removedProducts.length,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  selectedOptionInAlertAboutRemoving(data: IParamChangeProductInCart) {
    const eventName = 'shop_cart_remove_option_select';
    const innerSource = MobappMapper.innerSource();
    const parameters = {
      ...getProductInCartParams(data),
      ...innerSource,
      product: 'shop',
      source: 'app',
      selection_apply: data.isSelect,
      delete_quantity: data.product?.quantity ?? data.productsQuantity,
      started_by: data.startedBy,
      option: data.label,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedCartProductCheckbox(data: IParamChangeProductInCart) {
    const eventName = 'shop_cart_checkbox_click';
    const baseParams = getBaseParams();
    const innerSource = MobappMapper.innerSource();
    const parameters = {
      ...baseParams,
      ...getCartParams(data.cart),
      ...innerSource,
      selected_item: data.selectedItem,
      type_click: data.isSelect ? 'select' : 'diselect',
      suborder_count: data.groupsLength,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedCartOutOfStockMoreInfo(data: IParamChangeProductInCart) {
    const eventName = 'shop_cart_not_available_detail_click';

    const merchants = getMerchantsInfo(data.outOfStockProducts);

    const changedProductsCategoryLevels = getChangedCategoryLevels(
      data.outOfStockProducts
    );

    const parameters = {
      ...getBaseParams(),
      ...getCartParams(data.cart),
      ...changedProductsCategoryLevels,
      suborder_count: data.groupsLength,
      amount_unique: data.outOfStockProducts?.length,
      supplier_name_not_in_stock: merchants.merchantsNames,
      supplier_id_not_in_stock: merchants.merchantsIds,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedCartPriceChangedMoreInfo(data: IParamChangeProductInCart) {
    const eventName = 'shop_cart_price_change_detail_click';

    const supplier = getMerchantsInfo(data.staleProducts);

    const priceChange = new Set();

    const changedProductsCategoryLevels = getChangedCategoryLevels(
      data.staleProducts
    );

    data.staleProducts.forEach((product) => {
      const stalePrice = Number(product.stalePriceFormatted?.slice(0, -2)) || 0;

      if (stalePrice < product.price) {
        priceChange.add('decrease');
      } else if (stalePrice > product.price) {
        priceChange.add('increase');
      }
    });

    const parameters = {
      ...getBaseParams(),
      ...getCartParams(data.cart),
      ...changedProductsCategoryLevels,
      suborder_count: data.groupsLength,
      amount_unique: data.staleProducts?.length,
      supplier_name: supplier.merchantsNames,
      supplier_id: supplier.merchantsIds,
      price_change:
        priceChange.size === 2 ? 'both' : Array.from(priceChange)[0],
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedBuyNowButton(data) {
    const eventName = 'shop_buy_now_click';

    const baseParams = getBaseParams();
    const offerBaseParams = getOfferBaseParams(data.offer);
    const productInfoData = combineProductEventProperties(data);
    const productPromo = getProductPromo(data.promo);

    const parameters = {
      ...baseParams,
      ...offerBaseParams,
      ...productInfoData,
      supplier_position: data.positionId,
      quantity: data.quantity,
      promo: productPromo?.promo,
      // listing_position: '', //* param is not available yet
      query_id: getFromUrl.queryId(),
      referrer: getFromUrl.referrer(),
      ref: getFromUrl.ref(),
      ssohash: data.ssohash,
      is_discount: !!data.offer?.discountAmount,
      test_discount_amount: data.offer?.discountAmount || false,
      seller_rating: data.sellerRating,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  swipedCatalogItemImage(data: IParamSwipedCatalogItemImage) {
    const eventName = 'shop_category_listing_photo_swipe';

    const baseParams = getBaseParams();
    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.product.category
    );
    const productInfo = getProductInfo(data.product);
    const productPromo = getProductPromo(data.promo);
    const startedFrom = getStartedFrom();

    const parameters = {
      ...baseParams,
      ...categoryHierarchyProps,
      ...productInfo,
      ...productPromo,
      ...startedFrom,
      listing_position: data.listingPosition,
      image_position: data.sliderData.activeSlide + 1,
      is_last_image:
        data.sliderData.activeSlide + 1 === data.sliderData.slidesCount,
      referrer: getFromUrl.referrer(),
      query_id: data.queryId,
      promo: productPromo.promo,
      created_time: transformCreatedTime(data.product?.createdTime),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  swipedProductImage(data: IParamSwipedProductImage) {
    const eventName = 'shop_product_photo_swipe';

    const productPromo = getProductPromo(data.promo);
    const hasConfigurator = data.hasConfigurator;

    const parameters = {
      ...combineProductEventProperties(data),
      kd_offers_count: data.product.kd_offers_count,
      promo: productPromo.promo,
      has_configurator: !!hasConfigurator,
      image_position: data.sliderData.activeSlide + 1,
      is_last_image:
        data.sliderData.activeSlide + 1 === data.sliderData.slidesCount,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopMainPastOrderView(data: any) {
    const eventName = 'shop_main_past_order_view';

    const path = document.location.pathname;
    const parameters = {
      path,
      category_total: data.categoryTags.length,
      category_level_1: data.categoryTags[0]?.title,
      total: data.total,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopMainPastOrdersClick() {
    const eventName = 'shop_main_past_orders_click';

    const path = document.location.pathname;
    const parameters = {
      path,
      page_category: getPageCategory(path),
    };
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopMainMagnumPastOrderView(data) {
    const eventName = 'shop_main_magnum_past_order_view';

    const parameters = {
      category: window.digitalData.page?.title,
      block_id: data.pcm?.category_id,
      block_type: data.entity,
      block_name: data.header || data.title,
      block_position: data.position,
      block_source: data.promotedByPcm ? 'pcm' : '',
      block_style: data.style,
    };
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopLandingPageView(data: IParamShopLandingPageViewed) {
    const eventName = 'shop_landing_page_view';
    const path = document.location.pathname;

    const parameters = {
      path,
      has_pastorder: data.hasPastOrder,
      delivery_area: data.deliveryArea,
      utm_campaign: getFromUrl.utmCampaign(),
      ...combineMarketingEventProperties(),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedProductReviewLink(data: any) {
    const eventName = 'shop_order_product_review_clicked';
    const path = document.location.pathname;
    const startedFrom = getStartedFrom();
    const categoryHierarchyProps = getCategoryHierarchyProps(data.category);

    const parameters = {
      path,
      ...startedFrom,
      order_id: data.orderId,
      current_status: data.currentStatus,
      product_id: data.productCode,
      product_name: data.name,
      brand: data.brand,
      ...categoryHierarchyProps,
      payment_method: data.paymentMethod,
      price: data.price,
      product_type: (data.unit && productUnitType[data.unit.type]) || '',
      supplier: data.merchant,
      supplier_id: data.merchantId,
      ref: getFromUrl.ref(),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopProductReviewAddPageView(data: any) {
    const eventName = 'shop_product_review_add_view';
    const parameters = getProductReviewParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopProductReviewRatingSelect(data: any) {
    const eventName = 'shop_product_review_rating_select';
    const parameters = { ...getProductReviewParams(data), rating: data.rating };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopProductReviewCommentEnter(data: any) {
    const eventName = 'shop_product_review_comment_enter';
    const parameters = getProductReviewParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopProductReviewPhotoUploadClick(data: any) {
    const eventName = 'shop_order_review_photo_upload_click';
    const parameters = getProductReviewParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  shopProductReviewAdded(data: any) {
    const eventName = 'shop_product_review_added_view';
    const review = {
      rating: data.rating,
      has_rating: Boolean(data.rating),
      has_dignity: Boolean(data.positive),
      has_deficiencies: Boolean(data.negative),
      has_comment: Boolean(data.comment),
      has_image: data.media?.length > 0,
      image_count: data.media?.length,
      reason: data.reason,
    };

    const parameters = { ...getProductReviewParams(data), ...review };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedItemCard(data: IParamProductTeaser) {
    const eventName = 'shop_product_teaser_view';

    const baseParams = getBaseParams();

    if (data.isBottomPosition) {
      baseParams.page_category = baseParams.page_category + ' Bottom';
    }

    if (
      data.blockData?.blockType ===
      PCMBlockTypes.AUCTION_MERCHANT_GOODS_HORIZONTAL_CAROUSEL
    ) {
      baseParams.page_category = PageCategory.SIMILAR_PRODUCTS;
    }

    if (data.isEmptyCart) {
      baseParams.page_category = PageCategory.EMPTY_CART;
    }

    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.product?.category
    );
    const productInfo = getProductInfo(data.product);

    const adInfo = getAdInfoFromData(data.adsSupplierId, data.adsUniqueId);
    const blockData = getPcmBlockData(data.blockData);

    const parameters = {
      ...baseParams,
      ...categoryHierarchyProps,
      ...productInfo,
      ...adInfo,
      ...blockData,
      ads_unique_id: data.adsUniqueId,
      campaign_id: data.campaignId,
      category_id: data.categoryId,
      listing_position: data.listingPosition,
      search_term: data.searchTerm,
      query_id: data.queryId,
      ssohash: data.ssohash,
      source_sku: data.sourceSku,
      supplier_id: data.supplierId,
      cross_sell_backdrop_product_id: data.crossSellBackdropProductId,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedItemCard(data: IParamProductTeaser) {
    const eventName = 'shop_product_teaser_click';

    const baseParams = getBaseParams();
    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.product?.category
    );
    const productInfo = getProductInfo(data.product);
    const adInfo = getAdInfoFromData(data.adsSupplierId, data.adsUniqueId);
    const blockData = getPcmBlockData(data.blockData);

    if (data.isBottomPosition) {
      baseParams.page_category = baseParams.page_category + ' Bottom';
    }

    if (
      data.blockData?.blockType ===
      PCMBlockTypes.AUCTION_MERCHANT_GOODS_HORIZONTAL_CAROUSEL
    ) {
      baseParams.page_category = PageCategory.SIMILAR_PRODUCTS;
    }

    if (data.isEmptyCart) {
      baseParams.page_category = PageCategory.EMPTY_CART;
    }

    const parameters = {
      ...baseParams,
      ...categoryHierarchyProps,
      ...productInfo,
      ...adInfo,
      ...blockData,
      ads_unique_id: data.adsUniqueId,
      campaign_id: data.campaignId,
      category_id: data.categoryId,
      listing_position: data.listingPosition,
      search_term: data.searchTerm,
      query_id: data.queryId,
      ssohash: data.ssohash,
      source_sku: data.sourceSku,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  addedProductToFavorites(data: IParamProductTeaser) {
    const eventName = 'shop_favorite_add';

    const baseParams = getBaseParams();
    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.product?.category
    );
    const productInfo = getProductInfo(data.product);

    const adInfo = getAdInfo(data.adsSupplierId, data.adsUniqueId);
    const crossSellData = getPcmCrossSellData(data.crossSellData);

    const parameters = {
      ...baseParams,
      ...categoryHierarchyProps,
      ...productInfo,
      ...adInfo,
      ...crossSellData,
      product_id: productInfo.product_id,
      product_name: productInfo.product_name,
      is_discount: !!(data.product.discount || data.product.discountAmount),
      brand: productInfo.brand ?? data.product.promoConditions.brand,
      price: productInfo.price,
      has_configurator:
        productInfo.has_configurator ??
        !!data.product.promoConditions?.baseProductCodes?.length,
      product_type: data.product?.unit.type,
      review_count: productInfo.review_count ?? data.product.reviewsQuantity,
      listing_position: data.listingPosition ?? getFromUrl.listingPosition(),
      search_term:
        getFromUrl.searchQuery() ||
        data.searchTerm ||
        getSearchTermFromPrevSearchPage(),
      query_id: data.queryId ?? getFromUrl.queryId(),
      referrer: getFromUrl.referrer(),
      credit_monthly_price: String(
        productInfo.credit_monthly_price ?? ''
      )?.replace(/\D+/g, ''),
      ref: getFromUrl.ref(),
      source: 'app',
      ssohash: data.ssohash,
      started_by: data.startedBy || crossSellData.started_by,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  removedProductFromFavorites(data: IParamProductTeaser) {
    const eventName = 'shop_favorite_remove';

    const baseParams = getBaseParams();
    const categoryHierarchyProps = getCategoryHierarchyProps(
      data.product?.category
    );
    const productInfo = getProductInfo(data.product);

    const adInfo = getAdInfo(data.adsSupplierId, data.adsUniqueId);

    const parameters = {
      ...baseParams,
      ...categoryHierarchyProps,
      ...productInfo,
      ...adInfo,
      brand: productInfo.brand ?? data.product.promoConditions.brand,
      product_id: productInfo.product_id,
      product_name: productInfo.product_name,
      listing_position: data.listingPosition ?? getFromUrl.listingPosition(),
      price: productInfo.price,
      credit_monthly_price: String(
        productInfo.credit_monthly_price ?? ''
      )?.replace(/\D+/g, ''),
      product_type: data.product?.unit.type,
      created_time: productInfo.created_time,
      rating: productInfo.rating,
      review_count: productInfo.review_count ?? data.product.reviewsQuantity,
      image_count: productInfo.image_count,
      has_configurator:
        productInfo.has_configurator ??
        !!data.product.promoConditions?.baseProductCodes?.length,
      query_id: data.queryId ?? getFromUrl.queryId(),
      referrer: getFromUrl.referrer(),
      ref: getFromUrl.ref(),
      started_by: data.startedBy || null,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedFavoritesPage(data: IParamFavorites) {
    const eventName = 'shop_favorite_view';
    const favoritesCategoriesParams = getCategoriesParams(
      data.favorites?.map((item) => item.category)
    );

    const parameters = {
      products_quantity: data.total,
      products_available: data.favorites.filter(
        (favorite) => favorite.priceFormatted
      ).length,
      products_not_available: data.favorites.filter(
        (favorite) => !favorite.priceFormatted
      ).length,
      promo_sku_count: data.favorites.filter(
        (favorite) => favorite.priceFormatted
      ).length,
      ...favoritesCategoriesParams,
      // source: null, //* No description
      // started_from: null, //* No description
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedMyOrders(data: IMyOrderAnalytic) {
    const eventName = 'shop_my_orders';
    const params = getMyOrdersParam(data);
    const { inner_source } = MobappMapper.innerSource();
    this.sendEvent({
      event: eventName,
      parameters: {
        ...params,
        ssohash: data.ssohash,
        product: 'shop',
        inner_source,
      },
    });
  }

  clickedOrderDetailsCancelButton(data: IParamOrderPage) {
    const eventName = 'shop_order_details_cancel_button_click';
    const parameters = MobappMapper.combineCancellationData(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOrderCancellationButton(data: IParamOrderCancellationReason) {
    const eventName = 'shop_order_cancellation_button_click';
    const parameters = MobappMapper.combineCancellationData(data);

    this.sendEvent({
      event: eventName,
      parameters: {
        reason: data.reason.toLocaleLowerCase(),
        ...parameters,
      },
    });
  }

  selectedCancellationReason(data: IParamOrderCancellationReason) {
    const eventName = 'shop_order_cancellation_reason_select';
    const parameters = MobappMapper.combineCancellationData(data);
    const innerSource = MobappMapper.innerSource();

    this.sendEvent({
      event: eventName,
      parameters: {
        reason: data.reason.toLocaleLowerCase(),
        ...innerSource,
        ...parameters,
      },
    });

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedExperiment(data: IParamViewedExperiment) {
    const eventName = 'shop_viewed_experiment';
    const parameters = {
      experiment_id: data.id,
      variation_id: data.variationId,
      ssohash: data.ssohash,
      page_category: getPageCategory(window.location.pathname),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  // refunds
  clickedRefundButton(data: IParamOrderPage) {
    const eventName = 'shop_order_return_click';
    const parameters = getRefundInOrderParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCreateRefundButton(data: IParamOrderPage) {
    const eventName = 'shop_order_return_view';
    const parameters = getRefundInOrderParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRefundCreationPage(data: IParamOrderPage) {
    const eventName = 'shop_order_return_product_view';
    const parameters = getRefundInOrderParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOnProductSelectInRefundCreation(data: IProductToRefund) {
    const eventName = 'shop_order_return_product_select';
    const parameters = getProductForRefundParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRefundCreationWithReasonPage(data: IProductToRefund) {
    const eventName = 'shop_order_return_request_view';
    const parameters = getProductForRefundParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOnProductQuantityIncrement(data: IProductToRefund) {
    const eventName = 'shop_order_return_request_amount_select';
    const parameters = getProductForRefundParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOnRefundReasonSelect(data: IProductToRefund) {
    const eventName = 'shop_order_return_request_reason_select';
    const parameters = getProductForRefundParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOnRefundCommentInput(data: IProductToRefund) {
    const eventName = 'shop_orders_return_comments_start';
    const parameters = getProductForRefundParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOnRefundUploadPhoto(data: IProductToRefund) {
    const eventName = 'shop_order_return_photo_upload_click';
    const parameters = getProductForRefundParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRefundCreationAlert(data: IProductToRefund) {
    const eventName = 'shop_order_return_alert_view';
    const parameters = getProductForRefundParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOnRefundCreationButton(data: IProductToRefund) {
    const eventName = 'shop_order_return_request_click';
    const parameters = getProductForRefundParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedRefundCreationTYPPage(data: IProductToRefund) {
    const eventName = 'shop_order_return_typ_view';
    const parameters = getProductForRefundParams(data);

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedOnBackToOrdersAfterRefundCreation() {
    const eventName = 'shop_my_orders_back_click';
    const parameters = {};

    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnRefundsListLinkInOrders(data: { requestsCount: number }) {
    const eventName = 'shop_my_orders_return_requests_click';
    const startedFrom = getStartedFrom();
    const parameters = {
      in_progress_count: data.requestsCount,
      source: getFromUrl.source(),
      ...startedFrom,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedRefundsListPage(data: { total: number }) {
    const eventName = 'shop_return_requests_view';
    const startedFrom = getStartedFrom();
    const parameters = {
      total_count: data.total,
      source: getFromUrl.source(),
      ...startedFrom,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnRefundDetailsLinkInRefunds(data: { product: IRefundItemCard }) {
    const eventName = 'shop_return_request_details_click';
    const parameters = getRefundInListParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  viewedRefundDetailsLinkInOrders(data: {
    entry: IOrderEntry;
    orderId: string;
  }) {
    const eventName = 'shop_orders_details_button_view';
    const parameters = getRefundInOrder(data);

    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnRefundDetailsLinkInOrders(data: {
    entry: IOrderEntry;
    orderId: string;
  }) {
    const eventName = 'shop_order_details_return_details_click';
    const parameters = getRefundInOrder(data);

    this.sendEvent({ event: eventName, parameters });
  }

  viewedRefundDetailsPage(data: IRefundDetails) {
    const eventName = 'shop_return_request_details_view';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnCancelRefundButton(data: IRefundDetails) {
    const eventName = 'shop_return_request_cancel_click';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  viewedCancelRefundPage(data: IRefundDetails) {
    const eventName = 'shop_return_request_cancel_confirm_view';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnCancelRefundConfirmButton(data: IRefundDetails) {
    const eventName = 'shop_return_request_cancel_confirm_click';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  viewedConfirmOnCancelResultPage(data: IRefundDetails) {
    const eventName = 'shop_return_request_cancel_confirmed';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnSetDateAndAddressButton(data: IRefundDetails) {
    const eventName = 'shop_return_request_set_address_click';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnChangeDateAndAddressButton(data: IRefundDetails) {
    const eventName = 'shop_return_request_change_date_click';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  changeDateForDeliveryInRefund(data: IRefundDetails) {
    const eventName = 'shop_return_request_address_date_select';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnStartRefundDisputeButton(data: IRefundDetails) {
    const eventName = 'shop_return_request_dispute_click';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  viewedRefundDisputeAlert(data: IRefundDetails) {
    const eventName = 'shop_return_request_dispute_alert_view';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnDisputeAlertButton(data: IRefundDetails) {
    const eventName = 'shop_return_request_dispute_alert_click';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  viewedRefundDisputeTYP(data: IRefundDetails) {
    const eventName = 'shop_return_request_dispute_typ_view';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  clickedOnViewMoreComment(data: IRefundDetails) {
    const eventName = 'shop_return_request_reject_comment_view';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  viewedRefundRequiredAddressAlert(data: IRefundDetails) {
    const eventName = 'shop_return_request_address_alert_view';
    const parameters = getRefundDetailsParams(data);
    this.sendEvent({ event: eventName, parameters });
  }

  viewedRefundsListButton(data: IMyOrderAnalytic) {
    const eventName = 'shop_orders_return_application_view';
    this.sendEvent({
      event: eventName,
      parameters: getMyOrdersParam(data),
    });
  }

  viewedMainScreen() {
    const eventName = 'shop_main_screen_view';

    const baseParams = getBaseParams();

    const parameters = {
      ...baseParams,
      referrer: getFromUrl.referrer(),
      utm_campaign: getFromUrl.utmCampaign(),
      ...combineMarketingEventProperties(),
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  // banner
  viewedMainPromoBanner(data: IParamMainPagePromoBanner) {
    const eventName = 'shop_main_promo_banner_view';
    const parameters = getMainPromoBannerParams(data);
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  // banner
  clickMainPromoBanner(data: IParamMainPagePromoBanner) {
    const eventName = 'shop_main_promo_banner_click';
    const parameters = getMainPromoBannerParams(data);
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  // main slider
  viewedRecommendationBanner(data: IParamViewedRecommendationBanner) {
    const eventName = 'shop_main_banners_view';

    const parameters = {
      block_id: data.id,
      block_name: data.title,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  // main slider
  clickMainPageBanner(banner: IParamViewedRecommendationBanner) {
    const eventName = 'shop_main_banners_click';

    const parameters = {
      block_name: banner.title,
      block_position: banner.position,
      block_type_id: banner.id,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickMagnumMainPageBanner(data: any) {
    const eventName = 'shop_main_magnum_banners_click';

    this.sendEvent({
      event: eventName,
      parameters: getMagnumPcmBannerData(data),
    });
  }

  viewedMagnumMainPageBanner(data: any) {
    const eventName = 'shop_main_magnum_banners_view';
    this.sendEvent({
      event: eventName,
      parameters: getMagnumPcmBannerData(data),
    });
  }

  viewedMagnumStretchedBanner(data) {
    const eventName = 'shop_magnum_central_banner_view';
    const itemIndex = data.position - 1;

    const parameters = {
      banner_id: data.code,
      block_name: data.title,
      listitems_product_id: data.list[itemIndex].code,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickMagnumStretchedBanner(data) {
    const eventName = 'shop_magnum_central_banner_click';
    const itemIndex = data.position - 1;

    const parameters = {
      banner_id: data.code,
      block_name: data.title,
      listitems_product_id: data.list[itemIndex].code,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedBackdropRecommendation(data: IParamBackdropRecommendation) {
    const eventPushData = {
      event: 'shop_backdrop_view',
      parameters: {
        campaign_id: data.campaignId,
        page_category: getPageCategory(window.location.pathname),
      },
    };

    this.sendEvent(eventPushData);
  }

  clickedBackdropRecommendation(data: IParamBackdropRecommendation) {
    const eventPushData = {
      event: 'shop_backdrop_click',
      parameters: {
        campaign_id: data.campaignId,
        page_category: getPageCategory(window.location.pathname),
      },
    };

    this.sendEvent(eventPushData);
  }

  closedBackdropRecommendation(data: IParamBackdropRecommendation) {
    const eventPushData = {
      event: 'shop_backdrop_close',
      parameters: {
        campaign_id: data.campaignId,
        page_category: getPageCategory(window.location.pathname),
      },
    };

    this.sendEvent(eventPushData);
  }

  viewedBrandBlockRecommendation(data: IPcmBrandBlockData) {
    const eventPushData = {
      event: 'shop_main_brand_block_view',
      parameters: {
        block_type_id: data.code,
        block_name: data.header,
        block_position: data.position,
        listitems_product_id: data.itemCode,
        listitems_position: data.itemPosition,
      },
    };

    this.sendEvent(eventPushData);
  }

  clickedBrandItemRecommendation(data: IPcmBrandBlockData) {
    const eventPushData = {
      event: 'shop_main_brand_block_click',
      parameters: {
        block_type_id: data.code,
        block_name: data.header,
        block_position: data.position,
        listitems_product_id: data.itemCode,
        listitems_position: data.itemPosition,
      },
    };

    this.sendEvent(eventPushData);
  }

  clickedBrandLinkRecommendation() {
    const eventPushData = {
      event: 'shop_main_all_brand_click',
      parameters: {},
    };

    this.sendEvent(eventPushData);
  }

  viewedShopMerchantPage(data: IParamViewedShopMerchantPage) {
    const eventPushData = {
      event: 'shop_merchant_view',
      parameters: {
        supplier_id: data.supplierId,
        seller_rating: data.sellerRating,
        successful_sales: data.successfulSales,
        path: document.location.pathname,
        referrer: getFromUrl.referrer(),
        source: getFromUrl.source(),
        ...getStartedFrom(),
      },
    };

    this.sendEvent(eventPushData);
  }

  clickMerchantPhone(data: IParamClickMerchantPhone) {
    const eventName = 'shop_merchant_phone_click';

    const path = document.location.pathname;
    const startedFrom = getStartedFrom();

    const parameters = {
      ...startedFrom,
      path,
      referrer: getFromUrl.referrer(),
      supplier_id: data.merchantId,
    };
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickOrderDetailsCallMerchantButton(data: IParamClickCallMerchant) {
    const eventName = 'shop_order_details_call_merchant_click';

    const path = document.location.href;
    const startedFrom = getStartedFrom();

    const parameters = {
      ...startedFrom,
      path,
      source: getFromUrl.utmSource(),
      status: data.status,
      delivery_method: data.deliveryType,
    };
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickOrderDetailsCallCourierButton(data: IParamClickCallCourier) {
    const eventName = 'shop_contact_courier';

    const path = document.location.href;
    const startedFrom = getStartedFrom();

    const parameters = {
      ...startedFrom,
      path,
      source: getFromUrl.utmSource(),
      is_temp: data.isTemp,
    };
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickOrderDetailsPickupButton() {
    const eventName = 'shop_order_details_order_pick_up_click';

    const path = document.location.href;
    const startedFrom = getStartedFrom();

    const parameters = {
      ...startedFrom,
      action: 'order_details',
      path,
      source: getFromUrl.utmSource(),
    };
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickMyOrdersPickupButton(data: IParamClickMyOrdersPickup) {
    const eventName = 'shop_my_orders_order_pick_up_click';

    const startedFrom = getStartedFrom();

    const parameters = {
      ...startedFrom,
      action: 'my_orders',
      path: data.orderLink,
      source: getFromUrl.utmSource(),
    };
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickCityChange() {
    const eventPushData = {
      event: 'shop_city_change_click',
      parameters: {
        page_category: getPageCategory(window.location.pathname),
      },
    };

    this.sendEvent(eventPushData);
  }

  viewedCitySelectionDialog(city: string) {
    const eventPushData = {
      event: 'shop_city_selection_view',
      parameters: {
        city,
        page_category: getPageCategory(window.location.pathname),
      },
    };

    this.sendEvent(eventPushData);
  }

  clickCitySelectionChosen(city: string) {
    const eventPushData = {
      event: 'shop_city_selection_chosen',
      parameters: {
        city,
        page_category: getPageCategory(window.location.pathname),
      },
    };

    this.sendEvent(eventPushData);
  }

  // User viewed Magnum discount listing
  magnumDiscountView() {
    const eventName = 'shop_magnum_discount_view';

    const parameters = {
      ref: getFromUrl.ref(),
      path: document.location.href,
    };
    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedMagnumBackdropRecommendations() {
    const eventName = 'test_magnum_backdrop_recommendations_view';

    this.sendEvent({
      event: eventName,
      parameters: {},
    });
  }

  closeMagnumBackdropRecommendations() {
    const eventName = 'test_magnum_backdrop_recommendations_close';

    this.sendEvent({
      event: eventName,
      parameters: {},
    });
  }

  skipMagnumBackdropRecommendations() {
    const eventName = 'test_magnum_backdrop_recommendations_skip';

    this.sendEvent({
      event: eventName,
      parameters: {},
    });
  }

  continueMagnumBackdropRecommendations() {
    const eventName = 'test_backdrop_continue';

    this.sendEvent({
      event: eventName,
      parameters: {
        is_temp: true,
      },
    });
  }

  clickSupportChat(suborderId: string) {
    const eventName = 'shop_guide_open_click';
    const startedFrom = getStartedFrom();

    const parameters = {
      ...startedFrom,
      suborder_id: suborderId,
      path: [document.location.pathname + document.location.hash],
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickProductReviewLike({ count = '', rating = '', unique_id = '' }) {
    const eventName = 'shop_product_review_like_click';
    const parameters = {
      count,
      rating,
      unique_id,
      product_id: window.digitalData.product.id,
    };
    this.sendEvent({ event: eventName, parameters });
  }

  viewedShopProductAlert(text = '') {
    const eventName = 'shop_product_alert_view';
    const parameters = {
      alert_text: text,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickReviewFilter(data: IParamClickReviewFilter) {
    const eventName = 'shop_review_filter_clicked';
    const parameters = {
      page_category: getPageCategory(window.location.pathname),
      merchant_id: data.merchantId || null,
      merchant_name: data.merchantName || null,
      tab_clicked: data.tabClicked,
      review_count: data.reviewCount,
      review_tab_count: data.reviewTabCount,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickShareProduct(productId: string) {
    const eventName = 'shop_share_icon_clicked';

    const parameters = {
      product_id: productId,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedShopModelTagViewed(data: IParamClickedShopModelTagViewed) {
    const eventName = 'test_shop_model_tag_clicked';

    const categoryHierarchyArr = getCategoryHierarchyArr(data.treeCategory);
    const path = document.location.pathname;
    const pageCategory = getPageCategory(path);
    const parameters = {
      ...getCategoryHierarchyProps(categoryHierarchyArr),
      ...getStartedFrom(),
      listing: pageCategory,
      path: [path],
      title: data.title,
      value: data.selectedSort,
      test_model_tag_clicked_count: data.clickedCountModelTag,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedShopModelTag(data: IParamViewedShopModelTag) {
    const eventName = 'test_shop_model_tag_viewed';

    const appliedFilters = data.filters
      .filter((filter) => filter.active)
      .map((filter) => filter.title);
    const parameters = {
      ...combineListingEventProperties(data),
      ...getStartedFrom(),
      applied_filter: appliedFilters?.length ? appliedFilters : null,
      source: getFromUrl.source(),
      ssohash: getSessionId(),
      test_model_tag_clicked_count: data.clickedCountModelTag,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  removeShopModelTag(data: IParamClickedShopModelTagViewed) {
    const eventName = 'test_shop_model_tag_remove';

    const categoryHierarchyArr = getCategoryHierarchyArr(data.treeCategory);
    const path = document.location.pathname;
    const pageCategory = getPageCategory(path);
    const parameters = {
      ...getCategoryHierarchyProps(categoryHierarchyArr),
      ...getStartedFrom(),
      listing: pageCategory,
      path: [path],
      title: data.title,
      value: data.selectedSort,
      test_model_tag_clicked_count: data.clickedCountModelTag,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedAuctionBanner(data: IParamAuctionBlock) {
    const eventName = 'shop_auction_banner_view';

    const path = document.location.pathname;
    const pageCategory = getPageCategory(path);

    const parameters = {
      ...getAuctionBannerData(data),
      page_category: pageCategory,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickAuctionBanner(data: IParamAuctionBlock) {
    const eventName = 'shop_auction_banner_click';

    const path = document.location.pathname;
    const pageCategory = getPageCategory(path);

    const parameters = {
      ...getAuctionBannerData(data),
      page_category: pageCategory,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  // Share Cart
  shareCartClick(data: IGetShareCartParams) {
    const eventName = 'shop_cart_share_click';
    const shareCartParams = getShareCartParams(data);

    const parameters = {
      ...shareCartParams,
      path: document.location.pathname,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  shareCartBackdropView(data: IGetShareCartParams) {
    const eventName = 'shop_cart_share_backdrop_view';
    const shareCartParams = getShareCartParams(data);

    const parameters = {
      ...shareCartParams,
      path: document.location.pathname,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  shareCartBackdropClick(data: IGetShareCartParams) {
    const eventName = 'shop_cart_share_backdrop_click';
    const shareCartParams = getShareCartParams(data);

    const parameters = {
      ...shareCartParams,
      product_id: data.sharedEntries?.map((product) => product.masterSku),
      path: document.location.pathname,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  receiveCartBackdropView(data: IGetShareCartParams) {
    const eventName = 'shop_cart_received_view';
    const shareCartParams = getShareCartParams(data);

    const parameters = {
      ...shareCartParams,
      path: document.location.pathname,
      link: document.location.href,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  receivedCartAdd(data: IGetShareCartParams) {
    const eventName = 'shop_cart_received_cart_add';
    const shareCartParams = getShareCartParams(data);
    const parameters = {
      ...shareCartParams,
      product_id: data.sharedEntries?.map((product) => product.masterSku),
      path: document.location.pathname,
      link: document.location.href,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedSizeBackdrop(data: IParamViewedProductPage) {
    const eventName = 'shop_product_backdrop_view';
    const product = combineProductEventProperties(data);
    const globalPromotion = data.promo;
    const { promo = null } = getProductPromo(globalPromotion);
    const hasConfigurator = data.hasConfigurator;

    const parameters = {
      ...product,
      promo,
      has_configurator: !!hasConfigurator,
      started_by: data.startedBy,
      backdrop: data?.backdropName,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCertificatesStories() {
    const eventName = 'shop_certificate_stories_view';

    this.sendEvent({
      event: eventName,
      parameters: {},
    });
  }

  viewedCertificatesPreview(data: IParamViewedCertificatesPreview) {
    const eventName = 'shop_certificate_preview_view';

    const parameters = {
      amount: data.amount,
      started_from: data.startedFrom,
      time: data.time,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  clickedGiftCertificate(data: IParamClickedGiftCertificate) {
    const eventName = 'shop_certificate_click';

    const parameters = {
      amount: data.amount,
      position: data.position,
      time: data.time,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  viewedCertificatesAlert(data: IParamViewedCertificatesAlert) {
    const eventName = 'shop_certificate_alert_view';

    const parameters = {
      alert_text: data.alertText,
    };

    this.sendEvent({
      event: eventName,
      parameters,
    });
  }

  tireSelectionBannerClick(startedBy: StartedByTypes) {
    const eventName = 'shop_select_suitable_item';
    const parameters = {
      started_by: startedBy,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  tireSelectionFlowView(startedBy: StartedByTypes) {
    const eventName = 'shop_suitable_item_form_view';
    const parameters = {
      started_by: startedBy,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  tireSelectionFlowSubmit(data: IShopSuitableItemFormApplyParams) {
    const eventName = 'shop_suitable_item_form_apply';
    const parameters = {
      ...data,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedCartPcmProgressBar(data) {
    const eventName = 'shop_progress_bar_click';
    const cartParams = getProductInCartParams(data);
    const suppliersIds = getSuppliersIds(data);

    const parameters = {
      ...cartParams,
      ...suppliersIds,
      path: document.location.pathname,
    };

    this.sendEvent({ event: eventName, parameters });
  }

  viewedCartPcmProgressBar(data) {
    const eventName = 'shop_progress_bar_backdrop_view';
    const cartParams = getProductInCartParams(data);
    const suppliersIds = getSuppliersIds(data);

    const parameters = {
      ...cartParams,
      ...suppliersIds,
      path: document.location.pathname,
      listitems_total_count: data.items.length,
      product_id: data.items.map((item) => item.id),
    };

    this.sendEvent({ event: eventName, parameters });
  }

  clickedCartPcmProgressBarBackdrop(data) {
    const eventName = 'shop_progress_bar_backdrop_click';
    const cartParams = getProductInCartParams(data);
    const suppliersIds = getSuppliersIds(data);

    const parameters = {
      ...cartParams,
      ...suppliersIds,
      path: document.location.pathname,
      listitems_total_count: data.items.length,
      product_id: data.items.map((item) => item.id),
    };

    this.sendEvent({ event: eventName, parameters });
  }
}
